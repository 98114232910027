import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { getAppConfig } from '../../../appConfig';
import { useAppSelector } from '../../../state/stores/root.store';
import { CurrentTermActivityType } from '../../../types/activity.type';

export const useCurrentTerms = () => {
  const token = useMsalIdToken();
  const { currentStudent } = useAppSelector((state) => state.student);
  const { activeParent } = useAppSelector((state) => state.adminParent);
  const pupilID = String(currentStudent?.pupilId);
  const appConfig = getAppConfig();

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['student-current-terms', pupilID],
    enabled: !!token,
    queryFn: async () => {
      const res = await axios(
        `${appConfig.app.baseUrl!}activity/getcurrenttermactivitiesbypupilid`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
          params: {
            pupilID,
          },
        },
      );

      return res.data as Array<CurrentTermActivityType>;
    },
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
