import { Button, Stack, Typography, TableRow, TableCell } from '@mui/material';
import { TodoExcursionType } from '../../../types/todo.types';
import {
  formatActivityDate,
  IsMultiDayExcursion,
  formatMultiDayActivityDate,
} from '../formatDateTime';
import { displayTime12HourFormat } from '../../../utils/displayTime';
import { useState } from 'react';
import { TodoExcursionInfo } from './todo-excursion-info';
import { useGetPupils } from '../../../hooks/useGetPupils';
import { PupilType } from '../../../types/students.type';
import { StudentAvatar } from '../../../components/student-list/student-avatar';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface TodoExcursionProps {
  excursion: TodoExcursionType;
  setPermission: (
    type: 'success' | 'error' | 'default',
    currentStudent: string,
    message: string,
  ) => void;
  readonly?: boolean;
}

const TodoExcursion = ({
  excursion,
  setPermission,
  readonly,
}: TodoExcursionProps) => {
  const [isInfoOpen, setInfoOpen] = useState(false);
  const [currentExcursion, setExcursion] = useState<TodoExcursionType | null>(
    null,
  );

  const data = useGetPupils();
  const { getPupilInfo } = useGetPupils();

  const { isSmallDevice } = useMediaQuery();

  const pupil = data?.pupils?.filter(
    (pupilItem: PupilType) => pupilItem?.pupilId === excursion?.pupilID,
  )?.[0];

  const handleSelect = (todo: TodoExcursionType) => {
    setInfoOpen(true);
    setExcursion(todo);
  };

  const typographyStyle = {
    fontWeight: 'regular',
    fontSize: 13,
  };

  const tableCellStyle = {
    px: 0,
    py: 1,
  };

  const actionText = excursion?.action;
  const isReadOnly = actionText === 'Approved' || actionText === 'Declined';

  const commonButtonStyle = {
    opacity: '100%',
    borderRadius: '5px',
    width: '96px',
    height: '26px',
    textTransform: 'capitalize',
  };

  const buttonStyle = {
    Approved: {
      ...commonButtonStyle,
      bgcolor: '#1F3263',
      color: '#FFFFFF',
    },
    Declined: {
      ...commonButtonStyle,
      bgcolor: '#858080',
      color: '#FFFFFF',
    },
    Approve: {
      ...commonButtonStyle,
      bgcolor: '#916D4F',
      color: '#FFFFFF',
    },
  };

  return (
    <>
      <TableRow
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => handleSelect(excursion)}
      >
        <TableCell sx={tableCellStyle}>
          <Stack flexDirection='row' alignItems='center' gap={1}>
            <StudentAvatar
              fullName={''}
              acronym={getPupilInfo(excursion?.pupilID).avatarAcronym ?? ''}
              pupilId={excursion?.pupilID}
              size={36}
            />
            <Stack>
              <Typography
                sx={{
                  fontWeight: 'regular',
                  fontSize: 18,
                  color: '#1f3263',
                  width: '100px',
                }}
              >
                {pupil?.forename}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell align='left' sx={tableCellStyle}>
          <Typography
            sx={{
              ...typographyStyle,
              width: isSmallDevice ? '100px' : 'auto',
            }}
          >
            {excursion?.name}
          </Typography>
          {isSmallDevice && actionText ? (
            <Button
              variant='contained'
              sx={
                buttonStyle[actionText as 'Approved' | 'Declined' | 'Approve']
              }
            >
              {actionText.toLowerCase()}
            </Button>
          ) : null}
        </TableCell>
        <TableCell
          align='left'
          sx={{
            ...tableCellStyle,
            width: isSmallDevice ? '80px' : 'auto',
          }}
        >
          {IsMultiDayExcursion(
            String(excursion.runsFrom),
            String(excursion.runsTo),
          ) ? (
            <Typography
              sx={typographyStyle}
              dangerouslySetInnerHTML={{
                __html: formatMultiDayActivityDate(
                  String(excursion.runsFrom),
                  String(excursion.runsTo),
                  excursion.startTime,
                  excursion.endTime,
                ),
              }}
            />
          ) : (
            <>
              <Typography sx={typographyStyle}>
                {formatActivityDate(String(excursion.runsFrom))}
              </Typography>
              <Typography sx={typographyStyle}>
                {displayTime12HourFormat(excursion.startTime)} -{' '}
                {displayTime12HourFormat(excursion.endTime)}
              </Typography>
            </>
          )}
          <Typography
            sx={{
              ...typographyStyle,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => handleSelect(excursion)}
          >
            {!isSmallDevice ? 'View Info' : 'More Info'}
          </Typography>
        </TableCell>
        {!isSmallDevice ? (
          <TableCell align='center' sx={tableCellStyle}>
            {actionText && (
              <Button
                variant='contained'
                sx={
                  buttonStyle[actionText as 'Approved' | 'Declined' | 'Approve']
                }
              >
                {actionText.toLowerCase()}
              </Button>
            )}
          </TableCell>
        ) : null}
      </TableRow>
      {currentExcursion?.id && (
        <TodoExcursionInfo
          open={isInfoOpen}
          handleClose={() => setInfoOpen(false)}
          excursion={currentExcursion}
          setPermission={setPermission}
          readonly={isReadOnly}
        />
      )}
    </>
  );
};

export default TodoExcursion;
