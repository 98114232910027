const auth = {
  clientId: process.env.REACT_APP_MSAL_CLIENTID,
  authority: process.env.REACT_APP_MSAL_AUTHORITY,
  knownAuthoruty: process.env.REACT_APP_MSAL_KNOWN_AUTHORITY,
  redirectUri: window.location.origin,
};

const getConfigLocal = () => ({
  app: {
    baseUrl: process.env.REACT_APP_API_BASE_URL_LOCAL,
    baseUrlPage: process.env.REACT_APP_BASE_URL_LOCAL,
  },
  auth,
  gaID: process.env.REACT_APP_GTM_ID_DEV,
  title: process.env.REACT_APP_TITLE_LOCAL,
});

const getConfigDev = () => ({
  app: {
    baseUrl: process.env.REACT_APP_API_BASE_URL_DEV,
    baseUrlPage: process.env.REACT_APP_BASE_URL_DEV,
  },
  auth,
  gaID: process.env.REACT_APP_GTM_ID_DEV,
  title: process.env.REACT_APP_TITLE_DEV,
});

const getConfigProd = () => ({
  app: {
    baseUrl: process.env.REACT_APP_API_BASE_URL_PROD,
    baseUrlPage: process.env.REACT_APP_BASE_URL_PROD,
  },
  auth,
  gaID: process.env.REACT_APP_GTM_ID_PROD,
  title: process.env.REACT_APP_TITLE_PROD,
});

export const getAppConfig = () => {
  const location = window.location.href || '';

  if (
    location.includes(String(process.env.REACT_APP_MSAL_REDIRECT_URI_LOCAL))
  ) {
    return getConfigLocal();
  }

  if (location.includes(String(process.env.REACT_APP_MSAL_REDIRECT_URI_DEV))) {
    return getConfigDev();
  }

  return getConfigProd();
};
