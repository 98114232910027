import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { getAppConfig } from '../../../appConfig';
import axios from 'axios';
import { useAppSelector } from '../../../state/stores/root.store';

export const useProfile = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();
  const { activeParent } = useAppSelector((state) => state.adminParent);

  const { data, isLoading, isPending, isSuccess, isError, error } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['get-my-information'],
    enabled: !!token,
    queryFn: async () => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}parent/getmyinformation`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
        },
      );
      return res.data;
    },
  });

  return {
    profile: data,
    isLoading,
    isPending,
    isSuccess,
    isError,
    error,
  };
};
