import { WrapperView } from './WrapperView';

export const OtherLanguage = ({
  language,
  otherLanguage,
}: {
  language: string;
  otherLanguage: string;
}) => {
  return (
    <WrapperView
      label='Other Language at Home'
      value={otherLanguage ? otherLanguage : language}
    />
  );
};
