import { Box, Card, CircularProgress, Grid, Stack } from '@mui/material';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useGetSchoolInformationPathQuery } from '../../services/school.service';
import { useMsalIdToken } from '../../hooks/useMsalIdToken';
import { useEffect, useState } from 'react';
import { ContentPage } from '../../modules/school-information/components/contentPage';
import { SchoolInfoMenu } from '../../modules/school-information/components/nav-menu';
import { Breadcrumb } from '../../modules/school-information/components/breadcrumb';
import {
  useGetCoCurricularInformationQuery,
  useGetCocurricularInformationPathQuery,
} from '../../services/co-curricular.service';

export const CoCurricularInformationPage = () => {
  const token = useMsalIdToken();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const { data: schoolInformation } = useGetCoCurricularInformationQuery(
    { token },
    {
      skip: !token,
      refetchOnMountOrArgChange: false,
      refetchOnFocus: false,
    },
  );

  const { data: content, isFetching } = useGetCocurricularInformationPathQuery(
    {
      token: String(token),
      path: location.pathname,
    },
    {
      skip: !token && !schoolInformation,
      refetchOnMountOrArgChange: false,
      refetchOnFocus: false,
    },
  );

  useEffect(() => {
    if (location.pathname === '/co-curricular-information') {
      if (schoolInformation?.[0]?.item?.isPage) {
        navigate(schoolInformation?.[0]?.item.path);
      } else {
        navigate(schoolInformation?.[0]?.children?.[0]?.item?.path as string);
      }
    }
  }, [schoolInformation, location.pathname]);

  return (
    <Box id='cocurricular-info-container' height='100dvh'>
      <Grid container height={{ xs: 'auto', md: '100%' }} columnSpacing={2}>
        <Grid item xs={12} md={3}>
          <SchoolInfoMenu links={schoolInformation ?? []} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Breadcrumb
            title='Co-curricular Information'
            category={params?.category || ''}
            link={params?.link || ''}
          />
          <Card sx={{ height: 'calc(100% - 45px)' }}>
            {isFetching ? (
              <Stack
                height={'100%'}
                width={'100%'}
                justifyContent='center'
                alignItems='center'
                my={10}
              >
                <CircularProgress size={50} />
              </Stack>
            ) : (
              <ContentPage content={content} />
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
