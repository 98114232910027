import { Skeleton, SkeletonProps, Stack } from '@mui/material';

interface Props extends SkeletonProps {
  count?: number;
  sx?: Record<string, string | number>;
}

export const Loader = ({ count = 5, sx }: Props) => {
  return (
    <Stack gap={1} px={2} py={2} sx={sx}>
      {[...Array(count)]?.map(() => {
        const randomWidth = Math.floor(Math.random() * 31) + 50;
        return (
          <Stack flexDirection='row' gap={2} justifyContent='space-between'>
            <Skeleton
              variant='rectangular'
              width={`${randomWidth}%`}
              height={22}
              sx={{
                borderRadius: '5px',
                display: 'flex',
              }}
            />
            <Skeleton
              variant='rectangular'
              width='80px'
              height={22}
              sx={{
                borderRadius: '5px',
                display: 'flex',
                minWidth: '80px',
              }}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};
