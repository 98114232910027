const steps = [
  {
    selector: '[data-tour="coming-up"]',
    content:
      'This shows the date and time of your daughter’s next co-curricular activity and excursion.',
  },
  {
    selector: '[data-tour="excursion"]',
    content:
      'This shows a list of your daughter’s excursions with statuses to indicate whether or not you have given permission for your daughter to attend. Click on an excursion to view a pop up with further information and the ability to grant or refuse permission.',
  },
  {
    selector: '[data-tour="news"]',
    content:
      'This section shows you school news as well as notices sent directly to you. Clicking on a news item will open a pop up with the details. Click view more to see older news items. Subscribe to tags in My Profile to filter news items so they are more relevant.',
  },
  {
    selector: '[data-tour="upcoming-events"]',
    content:
      'This is a list of upcoming school events. Where relevant, events have a status to indicate that you need to RSVP/have already RSVP’d or that you need to book tickets.',
  },
  {
    selector: '[data-tour="students"]',
    content:
      'This allows you to select between your daughters, as this page is student-specific. Any page in Omnia that’s student specific will feature this selector.',
  },
  {
    selector: '[data-tour="key-information"]',
    content:
      'This section shows key information about your daughter, including a link to contact her class teacher or luminary, or submit an absentee form.',
  },
  {
    selector: '[data-tour="medical-information"]',
    content:
      'You can view medical information that the school holds on your daughter here. You can also request changes to that medical information by filling out a form in the medical details section.',
  },
  {
    selector: '[data-tour="reports"]',
    content:
      'This shows you reports for your daughter - click a report to open and view the report.',
  },
  {
    selector: '[data-tour="co-curricular"]',
    content:
      'This is a list of the co-curricular activities your daughter is participating in for the current term.',
  },
  {
    selector: '[data-tour="upcoming-approved-excursions"]',
    content:
      'This is a list of upcoming excursions for the current academic year that you have given your daughter permission to attend.',
  },
  {
    selector: '[data-tour="Timetable"]',
    content:
      'The timetable shows classes, co-curricular activities, and excursions for your daughter. Classes are blue, Co-curricular activities are TBC and Excursions are TBC. Junior school students have a block of class time, whereas senior school students have more detailed class information.',
  },
  {
    selector: '[data-tour="School Contacts"]',
    content:
      'Your daughter’s school contacts are listed here, including the email address.',
  },
  {
    selector: '[data-tour="School Calendar"]',
    content:
      'The school calendar shows all events happening in the school, and is filtered down to year groups to match your daughter’s year. Events are colour coded so that similar events will be the same colour.',
  },
  {
    selector: '[data-tour="school-info"]',
    content:
      'This section shows general information for parents of MLC School students. You can navigate around the different sections using the school information navigation panel. Each section will expand when you click on it.',
  },
  {
    selector: '[data-tour="my-profile"]',
    content:
      'This section shows information that the school holds on you. You can also request changes to your personal details and address.',
  },
  {
    selector: '[data-tour="subscribed-tags"]',
    content:
      'You can personalise your news items by susbcribing to tags of interest, similar to those you subcribe to in the MLC School app. Subscribing in the app does NOT filter your news feed here in OMNIA. You must subscribe in EACH platform. Please review your subscriptions regularly.',
  },
];

export default steps;
