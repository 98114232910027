import { Box, Stack, Typography } from '@mui/material';
import { SubscribedTagsItem } from './subscribed-tags-item';
import { useSubscribedTags } from './useSubscribedTags';
import { CategoryResponse } from '../../../types/category.type';

interface Props {
  tags: CategoryResponse;
}

export const SubscribedTagsList = ({ tags }: Props) => {
  const tagCategories = tags?.tagCategories?.map((tagCategory) => {
    return {
      tagName: tagCategory?.name,
      tagId: tagCategory?.id,
      tags: tags?.suscribedTags?.filter(
        (tagItem) => tagItem?.tagCategoryId === tagCategory?.id,
      )[0]?.tags,
    };
  });

  return (
    <Stack py={2} gap={2}>
      {tagCategories?.map((item) => {
        if (!item?.tags) {
          return null;
        }

        return (
          <Stack flexDirection='column' gap={1} key={item?.tagId}>
            <Typography variant='body1' fontWeight={300}>
              {item?.tagName}
            </Typography>
            <Stack flexDirection='row' flexWrap='wrap' gap={1}>
              {tags?.suscribedTags?.map((subscribedTag) => {
                if (item?.tagId !== subscribedTag?.tagCategoryId) {
                  return null;
                }

                return (
                  <>
                    {subscribedTag?.tags?.map((tag) => {
                      return (
                        <SubscribedTagsItem key={tag?.id} title={tag?.name} />
                      );
                    })}
                  </>
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
