import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

interface CloseInfoProps {
  close: () => void;
}

const CloseInfo = ({ close }: CloseInfoProps) => {
  return (
    <IconButton
      onClick={close}
      sx={{
        bgcolor: '#000000',
        position: 'absolute',
        width: 24,
        height: 24,
        p: 1,
        right: -12,
        top: -12,
        color: '#FFFFFF',
        '&:hover': {
          bgcolor: '#000000',
        },
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export { CloseInfo };
