import * as z from 'zod';

export const schema = z.object({
  forename: z
    .string({ required_error: 'Forename is required' })
    .min(1, { message: 'Forename is required' }),
  surname: z
    .string({ required_error: 'Surname is required' })
    .min(1, { message: 'Surname is required' }),
  email: z.string().email(),
  mobile: z
    .string()
    .min(10, { message: 'Invalid mobile number' })
    .or(z.literal('')),
  work: z
    .string()
    .min(10, { message: 'Invalid contact number' })
    .or(z.literal('')),
  home: z
    .string()
    .min(10, { message: 'Invalid contact number' })
    .or(z.literal('')),
});
