import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Routes from './routes';
import { theme } from './theme';
import store from './state/stores/root.store';
import { getAppConfig } from './appConfig';

import 'react-toastify/dist/ReactToastify.min.css';

const defaultTheme = createTheme(theme);

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const persistor = persistStore(store);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      },
    },
  });

  const appConfig = getAppConfig();

  useEffect(() => {
    document.title = appConfig.title || 'Omnia Portal';
  }, [appConfig.title]);

  return (
    <div className='App'>
      <MsalProvider instance={pca}>
        <ThemeProvider theme={defaultTheme}>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <Routes />
                </PersistGate>
              </Provider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </BrowserRouter>
        </ThemeProvider>
      </MsalProvider>
    </div>
  );
}

export default App;
