export const countries = [
  {
    name: 'Australia',
    code: '1101',
  },
  {
    name: 'China(excl.HK SARs & Taiwan Province)',
    code: '6101',
  },
  {
    name: 'England',
    code: '2102',
  },
  {
    name: 'Hong Kong(SAR of China)',
    code: '6102',
  },
  {
    name: 'India',
    code: '7103',
  },
  {
    name: 'Japan',
    code: '6201',
  },
  {
    name: 'Korea, Republic of (South)',
    code: '6203',
  },
  {
    name: 'New Zealand',
    code: '1201',
  },
  {
    name: 'United Kingdom',
    code: '2100',
  },
  {
    name: 'United States of America',
    code: '8104',
  },
  {
    name: 'Vietnam',
    code: '5105',
  },
  {
    name: 'Others',
    code: '0000',
  },
];
export const languages = [
  'English',
  'Arabic',
  'Cantonese',
  'Italian',
  'Vietnamese',
  'Mandarin',
  'Greek',
  'Spanish',
  'Tagalog',
  'Kriol',
  'Hindi',
  'Turkish',
  'Macedonian',
  'Samoan',
  'Polish',
  'Serbian',
  'German',
  'Indonesian',
  'Malay',
  'Sudanese',
  'Dutch',
  'Croatian',
  'Portuguese',
  'French',
  'Other',
];
export const occupations = [
  {
    group: 'Group 1',
    label:
      'Group 1: Elected officials, senior executives/manager, management in large business organisation, government administration and defence, and qualified professionals',
  },
  {
    group: 'Group 2',
    label:
      'Group 2: Other business managers/professionals and associate professionals',
  },
  {
    group: 'Group 3',
    label:
      'Group 3: Tradespeople and advanced/intermediate clerical, office, sales, carer and service staff',
  },
  {
    group: 'Group 4',
    label:
      'Group 4: Machine operators, sales/office/service/hospitality staff, assistants, labourers and related workers',
  },
  {
    group: 'Group 8',
    label: 'Group 8: Not in paid work in last 12 months',
  },
];
export const qualifications = [
  'Year 12 or equivalent',
  'Year 11 or equivalent',
  'Year 10 or equivalent',
  'Year 9 or equivalent',
];
export const educations = [
  'Bachelor degree or above',
  'Advanced diploma / Diploma',
  'Certificate I to IV(including trade certificate)',
  'No non - school qualification',
];
