import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Notices } from '../../modules/dashboard/notices';
import { Events } from '../../modules/dashboard/events';
import { ComingUp } from '../../modules/dashboard/coming-up';
import { Excursions } from '../../modules/dashboard/todo/excursions';
import { Todo } from '../../modules/dashboard/todo';
import { useTour } from '@reactour/tour';
import { useLocation } from 'react-router-dom';
import { PATHS } from '../../types';
import { useAppSelector } from '../../state/stores/root.store';

export const DashboardPage = () => {
  const { setIsOpen } = useTour();
  const location = useLocation();
  const { isAdmin, hasParent } = useAppSelector((state) => state.adminParent);

  useEffect(() => {
    if (location.pathname === PATHS.TAKEATOUR) {
      setIsOpen(true);
    }
  }, [location.pathname]);

  if (isAdmin && !hasParent) {
    return null;
  }

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={2} alignItems='flex-start'>
        <Grid item xs={12} lg={6}>
          <ComingUp />
          <Box height={16} />
          <Notices />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Excursions />
          <Box height={16} />
          <Events />
        </Grid>
      </Grid>
    </Box>
  );
};
