import { colors } from './constants';

export const theme = {
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    body1: {
      fontSize: '0.875rem', // 14px
    },
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  button: {
    outlined: {
      textTransform: 'none',
    },
  },
};
