import {
  Card,
  Link,
  Box,
  Button,
  Drawer,
  Stack,
  List,
  ListItemButton,
  Collapse,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fragment, useEffect, useState } from 'react';
import { Link as RRLink, useLocation } from 'react-router-dom';
import { SchoolMenuType } from '../../../../types/schoolinformation.type';

const StyledCollapse = styled(Collapse)({
  transition: 'all',
  boxShadow: 'none',
});

interface SchoolInfoMenuProps {
  links: Array<SchoolMenuType>;
}

const SchoolInfoMenu = ({ links }: SchoolInfoMenuProps) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const MenuContent = () => {
    return (
      <List>
        {links?.map((link: SchoolMenuType, linkIndex) => {
          const isActiveParent = location?.pathname?.includes(
            String(link.item.path),
          );
          return (
            <Fragment>
              <ListItemButton
                sx={{
                  px: 0,
                  py: 1,
                  pr: 1,
                }}
              >
                <Link
                  component={RRLink}
                  to={link.children?.[0]?.item?.path}
                  sx={{
                    textDecoration: 'none',
                    color: '#000000',
                    width: '100%',
                    paddingY: 1,
                    paddingX: 2,
                    fontSize: 13,
                    fontWeight: isActiveParent ? 'bold' : 'normal',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  data-tour='school-info'
                >
                  {link.item.name}
                  {link.children.length > 0 && <ExpandMoreIcon />}
                </Link>
              </ListItemButton>

              <Fragment>
                {location?.pathname?.includes(String(link.item.path)) && (
                  <StyledCollapse
                    in={location?.pathname?.includes(String(link.item.path))}
                    timeout='auto'
                    unmountOnExit
                  >
                    <List
                      component='div'
                      disablePadding
                      sx={{
                        px: 0,
                        pl: 2,
                      }}
                    >
                      {link.children?.map((itemLink) => {
                        const isActiveChild = location?.pathname?.includes(
                          String(itemLink.item.path),
                        );
                        return (
                          <Fragment>
                            <ListItemButton
                              sx={{
                                p: 0,
                                py: 1,
                                pr: 1,
                                borderBottom: 0,
                                bgcolor: isActiveChild ? '#1F3263' : 'none',
                                borderLeft: '1px solid #eff3f7',
                              }}
                            >
                              <Link
                                component={RRLink}
                                to={itemLink.item.path}
                                sx={{
                                  textDecoration: 'none',
                                  paddingY: 1,
                                  paddingX: 2,
                                  width: '100%',
                                  fontSize: 13,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  fontWeight: isActiveChild ? 'bold' : 'normal',
                                  color: isActiveChild ? '#FFFFFF' : 'inherit',
                                  '&:hover': {
                                    color: 'inherit',
                                  },
                                }}
                              >
                                {itemLink.item.name}
                                {itemLink.children.length > 0 && (
                                  <ExpandMoreIcon
                                    sx={{
                                      color: isActiveChild
                                        ? '#FFFFFF'
                                        : 'inherit',
                                    }}
                                  />
                                )}
                              </Link>
                            </ListItemButton>

                            <Fragment>
                              <StyledCollapse
                                in={location?.pathname?.includes(
                                  String(itemLink.item.path),
                                )}
                                timeout='auto'
                                unmountOnExit
                              >
                                <List
                                  component='div'
                                  disablePadding
                                  sx={{ px: 0, pl: 2 }}
                                >
                                  {itemLink.children?.map((itemChildLink) => {
                                    return (
                                      <ListItemButton
                                        sx={{
                                          p: 0,
                                          border: 'none',
                                          borderLeft: '1px solid #eff3f7',
                                        }}
                                      >
                                        <Link
                                          component={RRLink}
                                          to={itemChildLink.item.path}
                                          style={{
                                            textDecoration: 'none',
                                            color: '#000000',
                                            paddingTop: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            paddingRight: 6,
                                            width: '100%',
                                            fontSize: 13,
                                            display: 'block',
                                            fontWeight:
                                              location?.pathname?.includes(
                                                String(itemChildLink.item.path),
                                              )
                                                ? 'bold'
                                                : 'normal',
                                            borderLeftWidth: 4,
                                            borderLeftColor:
                                              location?.pathname?.includes(
                                                String(itemChildLink.item.path),
                                              )
                                                ? '#1F3263'
                                                : 'transparent',
                                            borderLeftStyle: 'solid',
                                          }}
                                        >
                                          {itemChildLink.item.name}
                                        </Link>
                                      </ListItemButton>
                                    );
                                  })}
                                </List>
                              </StyledCollapse>
                            </Fragment>
                          </Fragment>
                        );
                      })}
                    </List>
                  </StyledCollapse>
                )}
              </Fragment>
            </Fragment>
          );
        })}
      </List>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Box>
      <Box display={{ xs: 'block', md: 'none' }} mb={2}>
        <Button
          variant='contained'
          color='primary'
          onClick={handleDrawerToggle}
        >
          Menu
        </Button>
        <Drawer
          variant='temporary'
          anchor='left'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            style: {
              padding: 20,
              backgroundColor: '#FFFFFF',
            },
          }}
        >
          {MenuContent()}
        </Drawer>
      </Box>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Card>{MenuContent()}</Card>
      </Box>
    </Box>
  );
};

export { SchoolInfoMenu };
