import { Box, Button, Stack, Typography } from '@mui/material';
import { InformationValue } from '../../components/information-value';
import { ContentCard } from '../../components/layout/ContentCard';
import { useState } from 'react';
import { FormContactInformation } from './form/contact-information';
import { useProfile } from './hooks/useProfile';

interface Props {
  email: string;
}

export const ProfileContactInformation = ({ email }: Props) => {
  const { profile } = useProfile();
  const fullName = `${profile?.forename} ${profile?.surname}`;

  const [isModalOpen, setModalOpen] = useState(false);

  const workNumber = profile?.telephoneNumbers?.find(
    (item: { description: string }) => item.description === 'Work',
  );
  const homeNumber = profile?.telephoneNumbers?.find(
    (item: { description: string }) => item.description === 'Home',
  );
  const mobileNumber = profile?.telephoneNumbers?.find(
    (item: { description: string }) => item.description === 'Mobile',
  );

  const Content = () => {
    return (
      <Box maxHeight='calc(calc(100vh - 80px) / 2.6)' overflow='auto' px={2}>
        <Stack gap={3} py={2}>
          <Stack gap={1}>
            <InformationValue infoName='Full name' infoValue={fullName ?? ''} />
            <InformationValue
              infoName='Email address'
              infoValue={email ?? 'No information held'}
            />
          </Stack>

          <Stack gap={1}>
            <Box>
              <Typography fontSize={12} fontWeight={600}>
                Phone Numbers
              </Typography>
            </Box>
            <Stack gap={1}>
              {/* Work */}
              <InformationValue
                infoName='Work'
                infoValue={workNumber?.phoneNumber ?? 'No information held'}
              />
              {/* Home */}
              <InformationValue
                infoName='Home'
                infoValue={homeNumber?.phoneNumber ?? 'No information held'}
              />
              {/* Mobile */}
              <InformationValue
                infoName='Mobile'
                infoValue={mobileNumber?.phoneNumber ?? 'No information held'}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <ContentCard
        title='Contact Information'
        headerCta={
          <Button
            variant='text'
            sx={{ display: 'flex', gap: 1 }}
            onClick={() => setModalOpen(true)}
          >
            <Typography color='primary' fontWeight={300} fontSize={12}>
              Edit Information
            </Typography>
          </Button>
        }
        content={<Content />}
      />
      <FormContactInformation
        values={{
          forename: profile?.forename ?? '',
          surname: profile?.surname ?? '',
          email: email ?? profile?.emailAddress,
          mobile:
            mobileNumber && mobileNumber.phoneNumber
              ? mobileNumber.phoneNumber
              : '',
          work:
            workNumber && workNumber.phoneNumber ? workNumber.phoneNumber : '',
          home:
            homeNumber && homeNumber?.phoneNumber
              ? homeNumber?.phoneNumber
              : '',
        }}
        isOpen={isModalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </>
  );
};
