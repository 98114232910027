import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from './useMsalIdToken';
import { getAppConfig } from '../appConfig';
import { useAppSelector } from '../state/stores/root.store';
import { PupilType } from '../types/students.type';

export const useStudents = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent, isAdmin } = useAppSelector(
    (state) => state.adminParent,
  );

  const enabled = isAdmin ? !!token && !!activeParent?.email : !!token;

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      'pupils',
      activeParent?.email && `pupils-${activeParent?.email}`,
    ],
    enabled,
    staleTime: Infinity,
    queryFn: async () => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}pupil/getmypupils`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
        },
      );
      return res.data;
    },
  });

  return {
    data: data?.sort((a: PupilType, b: PupilType) => {
      return b.yearGroup > a.yearGroup ? 1 : -1;
    }) as Array<PupilType>,
    error,
    isFetching,
  };
};
