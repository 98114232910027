import { Grid, Skeleton } from '@mui/material';
import { SectionCard } from '../../components';
import { ContactCard } from './contact-card';
import { useAppSelector } from '../../state/stores/root.store';
import { useMsalIdToken } from '../../hooks/useMsalIdToken';
import { TeacherCard } from './teacher-card';
import { useContacts } from './useContacts';
import { useLuminary } from '../../hooks/useLuminary';
import { Subjects } from '../../modules/students/subjects';
import { LuminaryCard } from '../../modules/students/luminary-card';
import { useGetLuminary } from '../../modules/students/hooks/useGetLuminary';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const LoadingView = () => {
  return (
    <>
      {[...Array(6)].map(() => (
        <Grid item lg={6} md={12} xs={12} my={1}>
          <SectionCard>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
          </SectionCard>
        </Grid>
      ))}
    </>
  );
};

export const ContactsPage = () => {
  const token = useMsalIdToken();
  const { currentStudent } = useAppSelector((state) => state.student);
  const { isAdmin, hasParent } = useAppSelector((state) => state.adminParent);

  const { firstRow, secondRow, isFetching, isSenior } = useContacts();

  const { data: luminary, error: errorLuminary } = useGetLuminary();

  useEffect(() => {
    if (errorLuminary?.message) {
      toast(`Error: failed to load Luminary information.`, {
        type: 'error',
      });
    }
  }, [errorLuminary?.message]);

  if (isFetching) {
    return (
      <Grid container spacing={2}>
        <LoadingView />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <Grid container spacing={2}>
          <>
            {isSenior && (
              <Grid item xs={12}>
                <Subjects />
              </Grid>
            )}
            {isSenior ? (
              <Grid item xs={12}>
                <LuminaryCard />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TeacherCard teacher={luminary} />
              </Grid>
            )}
            {firstRow?.map((contact, index) => (
              <Grid item xs={12} key={`contact-card-first-${index}`}>
                <ContactCard
                  contact={contact}
                  year={isSenior ? 'senior' : 'junior'}
                />
              </Grid>
            ))}
          </>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Grid container spacing={2}>
          {secondRow?.map((contact, index) => (
            <Grid item xs={12} key={`contact-card-second-${index}`}>
              <ContactCard
                contact={contact}
                year={isSenior ? 'senior' : 'junior'}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
