import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAppConfig } from '../appConfig';
import {
  SchoolMenuType,
  SchoolPageBlockType,
} from '../types/schoolinformation.type';
const appConfig = getAppConfig();

export const api = createApi({
  reducerPath: 'school',
  baseQuery: fetchBaseQuery({ baseUrl: appConfig.app.baseUrl! }),
  endpoints: (build) => ({
    getSchoolTerms: build.query({
      query: ({ token }) => ({
        url: 'school/getschoolterms',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getSchoolInformation: build.query<
      Array<SchoolMenuType>,
      { token?: string | null }
    >({
      query: ({ token }) => ({
        url: 'umbraco/getschoolinformation',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getSchoolInformationPath: build.query<
      SchoolPageBlockType,
      { token: string; path: string }
    >({
      query: ({ token, path }) => ({
        url: `umbraco/getschoolinformationpagebypath`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          path: encodeURIComponent(String(path)),
        },
      }),
    }),
    getSchoolSubNavMenu: build.query({
      query: ({ token, path }) => ({
        url: `umbraco/getsubnavmenu`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          path: encodeURIComponent(String(path)),
        },
      }),
    }),
  }),
});

export const {
  useGetSchoolTermsQuery,
  useGetSchoolInformationQuery,
  useGetSchoolInformationPathQuery,
  useGetSchoolSubNavMenuQuery,
} = api;
