import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { ContentCard } from '../../../components/layout/ContentCard';
import { useSubjects } from '../subjects/useSubjects';
import { Loader } from '../../../components/loader';

export const LuminaryCard = () => {
  const { data, isLoading } = useSubjects();
  const filteredData = data?.filter((item) => item.subjectName === 'Luminary');

  const Content = () => {
    return (
      <Box sx={{ py: (theme) => theme.spacing(1) }} px={2}>
        {filteredData?.[0]?.teacher && (
          <Typography
            variant='body1'
            sx={{ fontSize: '14px', fontWeight: 'bold' }}
          >
            {filteredData?.[0]?.teacher}
          </Typography>
        )}
        {filteredData?.[0]?.emailID && (
          <Link href={`mailto:${filteredData?.[0]?.emailID}`} target='_blank'>
            <Typography variant='body1' sx={{ fontSize: '14px' }}>
              {filteredData?.[0]?.emailID}
            </Typography>
          </Link>
        )}
      </Box>
    );
  };

  return (
    <ContentCard
      title='Luminary'
      content={isLoading ? <Loader count={10} /> : <Content />}
    />
  );
};
