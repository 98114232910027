import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Stack, Typography } from '@mui/material';

const message =
  'The School’s administrative team will review and process the request to update your contact information. Please allow for up to 3 school days during term time for your records to be updated. If your request is made outside of term time, it may take longer to process.\n\nPlease note where another parent lives at the same address, a request for address change is required to be made separately by <strong>them</strong>.';

const SuccessView = () => {
  return (
    <Stack justifyContent='center' alignItems='center' gap={2} py={3}>
      <TaskAltIcon color='success' sx={{ fontSize: 50 }} />
      <Typography fontSize={20} fontWeight={600}>
        Request submitted
      </Typography>
      <Typography
        textAlign='center'
        sx={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Stack>
  );
};

export { SuccessView };
