import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

interface Props {
  label: string;
  initialValue?: string;
  options: { value: string | number; label: string }[];
  size: 'small' | 'medium';
  value: string | null;
  onChange: (value: any) => void;
}

const FormDropdown = ({
  label,
  initialValue,
  options,
  size,
  value,
  onChange,
  ...props
}: Props) => {
  return (
    <FormControl fullWidth size={size}>
      <InputLabel>{label}</InputLabel>
      <Select value={initialValue} label={label} {...props}>
        {options?.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { FormDropdown };
