import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { getAppConfig } from '../../../appConfig';
import { CategoryResponse } from '../../../types/category.type';
import { useAppSelector } from '../../../state/stores/root.store';

export const useSubscribedTags = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();
  const { activeParent } = useAppSelector((state) => state.adminParent);

  const { data, isLoading, error } = useQuery<CategoryResponse>({
    refetchOnWindowFocus: false,
    queryKey: ['subscribed-tags'],
    enabled: !!token,
    queryFn: async () => {
      const res = await axios.get(`${appConfig.app.baseUrl!}tag/gettags`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'parent-email': activeParent?.email,
        },
      });
      return res.data;
    },
  });

  return {
    tags: data,
    isLoading,
    error,
  };
};

export const useSubscribedTagsMutation = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { mutate, isSuccess, isPending } = useMutation({
    mutationFn: async (payload: unknown) => {
      const response = await axios.post(
        `${appConfig.app.baseUrl!}tag/updatetags`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response;
    },
  });

  return {
    updateTags: mutate,
    isSuccess,
    isSaving: isPending,
  };
};
