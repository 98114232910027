import { useEffect, useState } from 'react';
import { SchoolContactType } from '../../types/students.type';
import { useAppSelector } from '../../state/stores/root.store';
import { checkYearGroup } from '../../utils/checkYearGroup';
import { useSchoolContacts } from '../../hooks/useSchoolContacts';

export const useContacts = () => {
  const { currentStudent } = useAppSelector((state) => state.student);

  const [firstRow, setFirstRow] = useState<SchoolContactType[]>([]);
  const [secondRow, setSecondRow] = useState<SchoolContactType[]>([]);

  const { data: contacts, isFetching } = useSchoolContacts({
    pupilId: String(currentStudent?.pupilId),
  });

  const { isSenior } = checkYearGroup(
    Number(currentStudent?.yearGroup?.split(' ')?.[1]),
  );

  useEffect(() => {
    const firstGroup: SchoolContactType[] = [];
    const secondGroup: SchoolContactType[] = [];
    if (isSenior) {
      contacts?.map((contact: SchoolContactType) => {
        if ([3, 4].includes(contact.contactType)) {
          firstGroup.push(contact);
        } else {
          secondGroup.push(contact);
        }
      });

      setFirstRow(firstGroup);
      setSecondRow(secondGroup.reverse());

      return;
    }

    contacts?.map((contact: SchoolContactType) => {
      if ([0, 1, 2].includes(contact.contactType)) {
        secondGroup.push(contact);
      } else {
        firstGroup.push(contact);
      }
    });

    setFirstRow(firstGroup);
    setSecondRow(secondGroup.reverse());

    return;
  }, [isSenior, contacts]);

  return {
    firstRow,
    secondRow,
    contacts,
    isFetching,
    isSenior,
  };
};
