import { Box, Link, Typography } from '@mui/material';
import { LuminaryType, SchoolContactType } from '../../types/students.type';
import { ContentCard } from '../../components/layout/ContentCard';

interface Props {
  teacher: LuminaryType;
}

export const TeacherCard = ({ teacher }: Props) => {
  const Content = () => {
    return (
      <Box sx={{ py: (theme) => theme.spacing(1) }} px={2}>
        {teacher?.name && (
          <Typography
            variant='body1'
            sx={{ fontSize: '14px', fontWeight: 'bold' }}
          >
            {`${teacher?.name} ${teacher?.surname}`}
          </Typography>
        )}
        {teacher?.email && (
          <Link href={`mailto:${teacher?.email}`} target='_blank'>
            <Typography variant='body1' sx={{ fontSize: '14px' }}>
              {teacher?.email}
            </Typography>
          </Link>
        )}
      </Box>
    );
  };

  return <ContentCard title='Teacher' content={<Content />} />;
};
