import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAppConfig } from '../appConfig';
import { ActivityType, CurrentTermActivityType } from '../types/activity.type';
import { ExcursionType } from '../types/excursion.type';
const appConfig = getAppConfig();

export const api = createApi({
  reducerPath: 'pupilActivities',
  baseQuery: fetchBaseQuery({ baseUrl: appConfig.app.baseUrl! }),
  endpoints: (build) => ({
    getActivitiesByPupilId: build.query({
      query: ({ token, dates, pupilId }) => ({
        url: 'activity/getactivitiesbypupilid',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          from: dates?.startStr,
          to: dates?.endStr,
          pupilId,
        },
      }),
      transformResponse: (response: ActivityType[]) => {
        const activityResponse = response?.map((item) => ({
          title: item?.name,
          start: item?.startAt,
          end: item?.endAt,
          extendedProps: {
            ...item,
            isActivity: true,
          },
        }));

        return activityResponse;
      },
    }),
    getCurrentTermActivitiesByPupilId: build.query<
      Array<CurrentTermActivityType>,
      { token: string; pupilId: string }
    >({
      query: ({ token, pupilId }) => ({
        url: 'activity/getcurrenttermactivitiesbypupilid',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pupilId,
        },
      }),
    }),
    getReports: build.query<
      Array<{ reportName: string; reportDate: string }>,
      { token: string; pupilId: string }
    >({
      query: ({ token, pupilId }) => ({
        url: 'activity/getReports',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pupilId,
        },
      }),
    }),
    getExcursions: build.query<
      Array<ExcursionType>,
      { token: string; pupilId: string }
    >({
      query: ({ token, pupilId }) => ({
        url: 'activity/gettermexcursionsbypupilid',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pupilId,
        },
      }),
    }),
    getExcursionsByPupilId: build.query({
      query: ({ token, dates, pupilId }) => ({
        url: 'activity/getexcursionsbypupilid',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          from: dates?.startStr,
          to: dates?.endStr,
          pupilId,
        },
      }),
      transformResponse: (response: ActivityType[]) => {
        const activityResponse = response?.map((item) => ({
          title: item?.name,
          start: item?.startAt,
          end: item?.endAt,
          extendedProps: {
            ...item,
            isActivity: true,
          },
        }));

        return activityResponse;
      },
    }),
  }),
});

export const {
  useGetActivitiesByPupilIdQuery,
  useGetExcursionsByPupilIdQuery,
  useGetCurrentTermActivitiesByPupilIdQuery,
  useGetReportsQuery,
  useGetExcursionsQuery,
} = api;
