import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAppConfig } from '../appConfig';
const appConfig = getAppConfig();

export const api = createApi({
  reducerPath: 'notices',
  baseQuery: fetchBaseQuery({ baseUrl: appConfig.app.baseUrl! }),
  endpoints: (build) => ({
    getNotices: build.query({
      query: ({ token, page }) => ({
        url: 'notice/getcombinednotices',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          itemsperpage: 10,
        },
      }),
    }),
  }),
});

export const { useGetNoticesQuery } = api;
