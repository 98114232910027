import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

interface SuccessViewProps {
  handleClose: () => void;
}

const SuccessView = ({ handleClose }: SuccessViewProps) => {
  return (
    <>
      <DialogContent>
        <Stack alignItems='center' gap={3}>
          <TaskAltIcon sx={{ fontSize: 60, color: 'rgb(0, 155, 0)' }} />
          <Typography fontWeight={500} fontSize={18} textAlign='center' px={3}>
            This change has been sent to the school nurse. The School’s medical
            team will process this information and respond with any questions.
            Please allow for up to 3 school days during term time for your
            daughter’s records to be updated. If your request is made outside of
            term time, it may take longer to process.
          </Typography>
          <Typography fontWeight={500} fontSize={18} textAlign='center' px={3}>
            If this needs to be processed immediately, please contact the school
            nurses at&nbsp;
            <a href='mailto:mlcnurse@mlcsyd.nsw.edu.au'>
              mlcnurse@mlcsyd.nsw.edu.au
            </a>
            &nbsp;or call the school office during school hours.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'flex-end' }}>
        <Button onClick={handleClose} variant='contained'>
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export { SuccessView };
