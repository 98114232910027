import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { getAppConfig } from '../../../appConfig';
import { useAppSelector } from '../../../state/stores/root.store';

type SubjectsType = {
  pupilID: string;
  subjectName: string;
  className: string;
  teacher: string;
  emailID: string;
};

export const useSubjects = () => {
  const { currentStudent } = useAppSelector((state) => state.student);
  const pupilID = String(currentStudent?.pupilId);
  const token = useMsalIdToken();
  const appConfig = getAppConfig();
  const { activeParent } = useAppSelector((state) => state.adminParent);

  const { data, isFetching, error } = useQuery<SubjectsType[]>({
    refetchOnWindowFocus: false,
    queryKey: ['student-subjects', pupilID],
    enabled: !!token,
    queryFn: async ({ signal }) => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}pupil/getclassesbypupilid`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
          params: {
            pupilID,
          },
          signal,
        },
      );
      return res.data;
    },
  });

  return {
    data,
    isLoading: isFetching,
    error,
  };
};
