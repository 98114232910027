import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { useMedicalInfo } from '../useMedicalInformation';

const ConfirmView = () => {
  const { confirmClose, handleClose, handleView, handleReset } =
    useMedicalInfo();
  return (
    <>
      <DialogContent>
        <Stack alignItems='center'>
          <Typography fontWeight={500} fontSize={18} textAlign='center'>
            All updated information will not be save once you leave.
          </Typography>
          <Typography fontWeight={500} fontSize={18} textAlign='center'>
            Are you sure?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button
          onClick={() => handleView('edit')}
          variant='contained'
          color='inherit'
        >
          No
        </Button>
        <Button
          onClick={() => {
            if (confirmClose) {
              return handleClose();
            }
            handleView('view');
            handleReset();
          }}
          variant='contained'
        >
          Yes
        </Button>
      </DialogActions>
    </>
  );
};

export { ConfirmView };
