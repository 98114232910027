import { DialogContent } from '@mui/material';
import { DialogWrapper } from './DialogWrapper';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const CalenderDialog = ({ children, isOpen, onClose }: Props) => {
  return (
    <DialogWrapper isOpen={isOpen} onClose={onClose}>
      <DialogContent>{children}</DialogContent>
    </DialogWrapper>
  );
};

export { CalenderDialog };
