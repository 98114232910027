import { Box, Stack, Divider, Typography } from '@mui/material';
import { NoticeItemType } from '../../../types/notices.type';
import { useNoticeDate } from './useNoticeDate';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface Props {
  notice: NoticeItemType;
  handleSelect: () => void;
}

export const NoticeItem = ({ notice, handleSelect }: Props) => {
  const { displayDate } = useNoticeDate(String(notice?.date));
  const { isSmallDevice } = useMediaQuery();

  return (
    <Stack
      flexDirection='row'
      alignItems='center'
      sx={{
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
      }}
      onClick={handleSelect}
      py={1}
      component='button'
    >
      <Box width={72}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 13,
            color: '#1F3263',
            textAlign: 'left',
            marginBottom: '9px',
          }}
        >
          {displayDate}
        </Typography>
        {!isSmallDevice ? <Divider orientation='horizontal' /> : null}
      </Box>
      <Box width={20}></Box>
      <Box width={200} flexGrow={1}>
        <Typography
          variant='body1'
          color='primary'
          pr={2}
          textAlign='left'
          marginBottom='8px'
        >
          {notice?.name || notice?.title}
        </Typography>
        {!isSmallDevice ? <Divider orientation='horizontal' /> : null}
      </Box>
    </Stack>
  );
};
