import { Stack, Typography } from '@mui/material';

interface Props {
  value: string;
  label: string;
}

export const WrapperView = ({ value, label }: Props) => {
  return (
    <Stack flex={1} pb={2}>
      <Typography fontSize={12}>{label}</Typography>
      <Typography
        fontSize={14}
        fontWeight={700}
        sx={{
          color: !value ? '#FF0000' : 'inherit',
        }}
      >
        {value || 'N/A'}
      </Typography>
    </Stack>
  );
};
