import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  name: string;
  imageUrl: string;
  isFetching?: boolean;
}

export const ContentImage = ({
  isFetching = false,
  imageUrl,
  name,
  ...props
}: Props) => {
  if (isFetching) {
    return <Box>Loading....</Box>;
  }

  return (
    <Box {...props}>{imageUrl ? <img src={imageUrl} alt={name} /> : null}</Box>
  );
};
