import { Box, Stack } from '@mui/material';
import { InformationValue } from '../../components/information-value';
import { ParentItemType } from '../../types/parent.type';
import { PupilType } from '../../types/students.type';
import { ContentCard } from '../../components/layout/ContentCard';

interface Props {
  info: ParentItemType;
  pupils: Array<PupilType>;
}

export const ProfileRelationshipToStudents = ({ info, pupils }: Props) => {
  const Content = () => {
    return (
      <Box maxHeight='calc(calc(100vh - 80px) / 2.6)' overflow='auto' px={2}>
        <Stack gap={3} py={2}>
          <Stack gap={1}>
            {info?.relationships?.map((relationship) => (
              <InformationValue
                key={`relationship-${relationship?.contactId}`}
                infoName={`${
                  pupils?.filter(
                    (pupil) => pupil?.pupilId === relationship?.studentId,
                  )?.[0]?.displayName
                }`}
                infoValue={relationship?.relationship ?? 'No information held'}
              />
            ))}
          </Stack>
        </Stack>
      </Box>
    );
  };

  return <ContentCard title='Relationship to Student' content={<Content />} />;
};
