import { Box, Link, Typography } from '@mui/material';
import { SchoolContactType } from '../../types/students.type';
import { ContentCard } from '../../components/layout/ContentCard';

interface Props {
  contact: SchoolContactType;
  year: 'senior' | 'junior';
}

export const ContactCard = ({ contact, year }: Props) => {
  const contactTitle = contact.contactTypeName?.includes('Of')
    ? contact.contactTypeName.replace('Of', 'of')
    : contact.contactTypeName;

  const contactName = contact?.contactName.replaceAll(
    '–',
    String.fromCharCode(8212),
  );
  const contactHeaderTitle = contactTitle.replaceAll(
    '-',
    String.fromCharCode(8212),
  );

  const Content = () => {
    return (
      <Box sx={{ py: (theme) => theme.spacing(1) }} px={2}>
        {contact?.contactName && (
          <Typography fontWeight={600} fontSize={14}>
            {contactName}
          </Typography>
        )}
        {contact?.houseName && (
          <Typography variant='body1' sx={{ fontSize: '14px' }}>
            {contact?.houseName}
          </Typography>
        )}
        {contact?.contactEmailAddress && (
          <Link href={`mailto:${contact?.contactEmailAddress}`} target='_blank'>
            <Typography variant='body1' sx={{ fontSize: '14px' }}>
              {contact?.contactEmailAddress}
            </Typography>
          </Link>
        )}
      </Box>
    );
  };

  return (
    <ContentCard
      title={
        contact.contactTypeName.toLowerCase() === 'head of house' &&
        year === 'junior'
          ? 'House Patron'
          : contactHeaderTitle
      }
      content={<Content />}
    />
  );
};
