export const colors = {
  primary: '#1F3263',
  secondary: '#906D4F',
  tertiary: '#FFFFFF',

  black: '#000000',
  white: '#FFFFFF',
  grey: '#F5F5F5',
  divider: '#BDBEC0',
};
