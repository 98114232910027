import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { TourProvider } from '@reactour/tour';
import { PATHS } from './types';
import { AppLayout } from './layouts/AppLayout';
import {
  ContactsPage,
  CalenderPage,
  DashboardPage,
  MyStudentsPage,
  TimetablePage,
  InformationPage,
  ErrorPage,
  ProfilePage,
  RootPage,
  CoCurricularInformationPage,
} from './pages/routes';

import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import steps from './config/toursteps.config';
import { AdminPage } from './pages/admin';
import { useMsalIdToken } from './hooks/useMsalIdToken';
import { useTokenDecode } from './hooks/useTokenDecode';
import { useAppDispatch } from './state/stores/root.store';
import { setIsAdmin } from './state/slices/admin-parent.slice';
import { CircularProgress, Stack } from '@mui/material';

export default function App() {
  useMsalAuthentication(InteractionType.Redirect, {
    ...msalConfig.auth,
  });

  const token = useMsalIdToken();
  const jwt = useTokenDecode(token);
  const dispatch = useAppDispatch();

  const redirect = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(null);

  const setCurrentStep = (stepNumber) => {
    switch (stepNumber) {
      case 4:
        if (step === 5) {
          redirect('/dashboard', true);
        }
        break;
      case 5:
        redirect('/my-students', true);
        break;
      case 10:
        if (step === 11) {
          redirect('/my-students', true);
        }
        break;
      case 11:
        redirect('/timetable', true);
        break;
      case 12:
        redirect('/contacts', true);
        break;
      case 13:
        redirect('/calendar', true);
        break;
      case 14:
        redirect('/school-information', true);
        break;
      case 15:
        redirect('/profile', true);
        break;
      default:
        break;
    }
    setStep(stepNumber);
  };

  const handleCloseTour = () => {
    setStep(0);
    setCurrentStep(0);
    if (location.pathname === '/takeatour') {
      redirect(PATHS.DASHBOARD);
    } else {
      redirect(location.pathname);
    }
  };

  if (!token) {
    return (
      <Stack
        alignItems='center'
        justifyContent='center'
        height='100dvh'
        width='100dvw'
        gap={4}
      >
        <CircularProgress size={40} />
      </Stack>
    );
  }

  if (token) {
    if (jwt?.groupsid.includes(process.env.REACT_APP_ADMIN_CLAIM)) {
      dispatch(setIsAdmin(true));
    }
  }

  return (
    <TourProvider
      steps={steps}
      currentStep={step}
      setCurrentStep={setCurrentStep}
      disableDotsNavigation
      showDots={false}
      onClickClose={(params) => {
        params.setIsOpen(false);
        handleCloseTour();
      }}
      onClickMask={(params) => {
        params.setIsOpen(false);
        handleCloseTour();
      }}
    >
      <AppLayout>
        <Routes>
          <Route path={PATHS.ROOT} element={<RootPage />} />
          <Route path={PATHS.MY_STUDENTS} element={<MyStudentsPage />} />
          <Route path={PATHS.TIMETABLE} element={<TimetablePage />} />
          <Route path={PATHS.SCHOOL_CONTACTS} element={<ContactsPage />} />
          <Route path={PATHS.SCHOOL_CALENDAR} element={<CalenderPage />} />
          <Route path={PATHS.ADMIN} element={<AdminPage />} />

          <Route
            path={PATHS.SCHOOL_INFORMATION}
            element={<InformationPage />}
          />
          <Route
            path={`${PATHS.SCHOOL_INFORMATION}/:category/:link/:pagechild/*`}
            element={<InformationPage />}
          />
          <Route
            path={`${PATHS.SCHOOL_INFORMATION}/:category/:link`}
            element={<InformationPage />}
          />

          <Route
            path={PATHS.COCURRICULAR}
            element={<CoCurricularInformationPage />}
          />
          <Route
            path={`${PATHS.COCURRICULAR}/:category/:link/:pagechild/*`}
            element={<CoCurricularInformationPage />}
          />
          <Route
            path={`${PATHS.COCURRICULAR}/:category/:link`}
            element={<CoCurricularInformationPage />}
          />

          <Route path={PATHS.DASHBOARD} element={<DashboardPage />} />
          <Route path={PATHS.PROFILE} element={<ProfilePage />} />
          <Route path={PATHS.TAKEATOUR} element={<DashboardPage />} />
          <Route element={<ErrorPage />} />
        </Routes>
      </AppLayout>
    </TourProvider>
  );
}
