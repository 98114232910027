import { ReactNode } from 'react';
import { Button, Box, Card, Typography } from '@mui/material';

interface SectionCardProps {
  heading?: string;
  children?: ReactNode;
  minHeight?: string | number;
  actionLabel?: string;
  onAction?: () => void;
}

export const SectionCard = ({
  heading,
  children,
  minHeight,
  actionLabel,
  onAction,
}: SectionCardProps) => (
  <Card
    variant='outlined'
    sx={{
      pt: 2,
      px: 2,
      minHeight: minHeight || 'auto',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {heading && (
      <>
        <Typography
          variant='body1'
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            pb: 1,
            borderBottom: '1px solid',
          }}
        >
          {heading}
        </Typography>
        <hr />
      </>
    )}
    <Box flex={1}>{children}</Box>

    {/* bottom right text button */}
    {actionLabel && onAction && (
      <Box display='flex' justifyContent='flex-end' pt={4}>
        <Button
          onClick={onAction}
          sx={{ fontSize: '11px', textTransform: 'none' }}
        >
          {actionLabel}
        </Button>
      </Box>
    )}
  </Card>
);
