import { Skeleton, Stack } from '@mui/material';

export const LoadingView = () => {
  return (
    <Stack px={3} py={2} gap={2}>
      <Stack flexDirection='column' gap={1}>
        <Skeleton
          variant='rectangular'
          width={`${20}%`}
          height={22}
          sx={{
            borderRadius: '5px',
            display: 'flex',
          }}
        />
        <Stack flexDirection='row' flexWrap='wrap' gap={1}>
          {[...Array(10)]?.map(() => (
            <Skeleton
              variant='rectangular'
              width={`${Math.floor(Math.random() * 10) + 20}%`}
              height={22}
              sx={{
                borderRadius: '5px',
                display: 'flex',
              }}
            />
          ))}
        </Stack>
      </Stack>

      <Stack flexDirection='column' gap={1}>
        <Skeleton
          variant='rectangular'
          width={`${20}%`}
          height={22}
          sx={{
            borderRadius: '5px',
            display: 'flex',
          }}
        />
        <Stack flexDirection='row' flexWrap='wrap' gap={1}>
          {[...Array(10)]?.map(() => (
            <Skeleton
              variant='rectangular'
              width={`${Math.floor(Math.random() * 10) + 20}%`}
              height={22}
              sx={{
                borderRadius: '5px',
                display: 'flex',
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
