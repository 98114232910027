import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Stack, Typography } from '@mui/material';

const message =
  'The School’s administrative team will review and process the request to update your address information. Please allow for up to 3 school days during term time for your records to be updated.\n\nIf your request is made outside of term time, it may take longer to process.';

const SuccessView = () => {
  return (
    <Stack justifyContent='center' alignItems='center' gap={2} py={4}>
      <TaskAltIcon color='success' sx={{ fontSize: 50 }} />
      <Typography fontSize={20} fontWeight={600}>
        Request submitted
      </Typography>
      <Typography textAlign='center' sx={{ whiteSpace: 'pre-line' }}>
        {message}
      </Typography>
    </Stack>
  );
};

export { SuccessView };
