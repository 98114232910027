import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../hooks/useMsalIdToken';
import { getAppConfig } from '../appConfig';
import { useAppSelector } from '../state/stores/root.store';

export const useExcursionByPupilId = ({
  dates,
  pupilId,
}: {
  dates: { startStr: string; endStr: string } | null;
  pupilId: string;
}) => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent, isAdmin } = useAppSelector(
    (state) => state.adminParent,
  );
  const enabled = isAdmin
    ? !!token && !!pupilId && !!activeParent?.email
    : !!token && !!pupilId;

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      'excursion-by-pupilId',
      `excursion-by-pupilId-${pupilId}`,
      activeParent?.email,
      dates,
    ],
    enabled,
    queryFn: async () => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}activity/getexcursionsbypupilid`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
          params: {
            from: dates?.startStr,
            to: dates?.endStr,
            pupilId,
          },
        },
      );
      return res.data;
    },
  });

  return {
    data: data?.map(
      (item: { name: string; startAt: string; endAt: string }) => ({
        title: item?.name,
        start: item?.startAt,
        end: item?.endAt,
        extendedProps: {
          ...item,
          isActivity: true,
        },
      }),
    ),
    error,
    isFetching,
  };
};
