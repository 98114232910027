import { Configuration, ProtocolMode } from '@azure/msal-browser';
import { getAppConfig } from './appConfig';
const appConfig = getAppConfig();

export const msalConfig: Configuration = {
  auth: {
    clientId: appConfig.auth.clientId!,
    authority: appConfig.auth.authority!,
    knownAuthorities: [appConfig.auth.knownAuthoruty!],
    redirectUri: appConfig.auth.redirectUri!,
    postLogoutRedirectUri: '/',
    protocolMode: ProtocolMode.AAD,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};
