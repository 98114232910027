import { Button, Divider, Link, Stack, Typography } from '@mui/material';
import { Dialog } from '../../../components/dialog';
import { NoticeAttachment, NoticeItemType } from '../../../types/notices.type';
import { useNoticeDate } from './useNoticeDate';
import { RteContentBlock } from '../../../components/content-blocks/rte-content-block';
import { ContentIframe } from '../../../components/content-blocks/content-iframe';
import { ContentVideo } from '../../../components/content-blocks/content-video';
import { ContentImage } from '../../../components/content-blocks/content-image';
import { ContentThumbnailImage } from '../../../components/content-blocks/content-thumbnail-image';
import { AttachmentFile } from './attachment/file';
import { AttachmentLink } from './attachment/link';
import { AttachmentContact } from './attachment/contact';
import { replaceCmsLinkToHtmlLink } from '../../../utils/replaceCmsLinkToHtmlLink';
import { convertLinkString } from '../../../utils/linkCheck';

const imageExtensions = ['png', 'jpg', 'jpeg', 'gif'];

const isImage = (fileName: string) => {
  const fileExtension = (fileName && fileName.split('.').pop()) || '';
  return imageExtensions.includes(fileExtension.toLowerCase());
};

const removePrefix = (fileName: string) => {
  const parts = fileName.split('_');
  // Check if the first part is an integer
  if (parts.length > 1 && !isNaN(Number(parts[0]))) {
    parts.shift(); // Remove the first element
  }
  return parts.join('_');
};

interface Props {
  open: boolean;
  notice?: NoticeItemType | null;
  handleClose: () => void;
}

interface ContactAttachmentProps {
  type: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  order: number;
}

interface LinkAttachmentProps {
  type: string;
  url: string;
  external_link: number;
  order: number;
}

interface FileAttachmentProps {
  type: string;
  url: string;
  external_link: number;
  file_size: string;
  fileName: string;
  order: number;
}

function convertEmailsToMailto(body: string) {
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
  return body.replace(emailRegex, '<a href="mailto:$1">$1</a>');
}

export const NoticeInfo = ({ open, notice, handleClose }: Props) => {
  const { displayDate } = useNoticeDate(String(notice?.date));
  const attachmentContacts: Array<NoticeAttachment> = [];
  const attachmentFiles: Array<NoticeAttachment> = [];
  const attachmentLinks: Array<NoticeAttachment> = [];
  const attachmentEngage: Array<NoticeAttachment> = [];

  notice?.attachments?.map((attachment) => {
    if (attachment.type === 'contact') {
      attachmentContacts.push(attachment as NoticeAttachment);
    }

    if (attachment.type === 'file') {
      attachmentFiles.push(attachment as NoticeAttachment);
    }

    if (attachment.type === 'link') {
      attachmentLinks.push(attachment as NoticeAttachment);
    }

    if (attachment.type === 'EngageSP') {
      attachmentEngage.push(attachment as NoticeAttachment);
    }
  });

  if (!notice) {
    return null;
  }

  return (
    <Dialog
      open={open}
      title={notice?.name || notice?.title}
      subtitle={notice?.staffName}
      handleClose={handleClose}
      actions={
        <Stack>
          <Button onClick={handleClose}>Close</Button>
        </Stack>
      }
      PaperProps={{
        style: {
          width: '100%',
        },
      }}
      id='notices-dialog'
    >
      <Stack py={3}>
        <Stack alignItems='flex-end' pb={2}>
          <Typography fontSize={12} fontWeight={600}>
            {displayDate}
          </Typography>
        </Stack>
        <Stack>
          {notice?.body ? (
            <Typography
              variant='body1'
              color='black'
              dangerouslySetInnerHTML={{
                __html: convertLinkString(
                  replaceCmsLinkToHtmlLink(convertEmailsToMailto(notice?.body)),
                ),
              }}
            />
          ) : null}

          {/* Content Blocks */}
          {notice?.blocks?.map((block, index) => {
            const parseBlock = JSON.parse(String(block));

            if (parseBlock?.ContentType === 'rTEContentBlock') {
              return (
                <RteContentBlock
                  key={`news-block-${index}`}
                  content={parseBlock?.Text}
                  sx={{
                    maxHeight: 'calc(100vh - 160px)',
                    overflow: 'auto',
                  }}
                />
              );
            }

            if (parseBlock?.ContentType === 'embedIframeBlock') {
              return (
                <ContentIframe
                  key={`content-iframe-${index}`}
                  content={parseBlock?.Iframe}
                  sx={{
                    width: '100%',
                    marginBottom: '100px',
                  }}
                />
              );
            }

            if (parseBlock.ContentType === 'videoEmbedBlock') {
              return (
                <ContentVideo
                  imageUrl={parseBlock?.Thumbnail}
                  videoId={parseBlock?.VideoId}
                  isOverlay={parseBlock?.Overlay}
                />
              );
            }

            if (parseBlock.ContentType === 'imageBlock') {
              return (
                <ContentImage
                  name={parseBlock?.Name}
                  imageUrl={parseBlock?.Url}
                  sx={{
                    width: '100%',
                    marginBottom: '100px',
                  }}
                />
              );
            }
          })}

          {/* Reply */}
          {notice?.staffEmail && (
            <Stack py={2}>
              <Button variant='contained' sx={{ p: 0 }}>
                <Link
                  href={`mailto:${notice?.staffEmail}`}
                  width='100%'
                  paddingY={1}
                  target='_blank'
                >
                  <Typography color='white' fontWeight={600}>
                    Reply
                  </Typography>
                </Link>
              </Button>
            </Stack>
          )}

          {/* Attachments */}
          {notice.attachments && notice.attachments.length > 0 ? (
            <Stack gap={2} pb={2} mt={2}>
              <Divider />
              <Typography fontSize={16} fontWeight={600}>
                Attachments
              </Typography>
            </Stack>
          ) : null}

          <Stack gap={2}>
            {attachmentContacts?.length !== 0 && (
              <>
                <Stack gap={1}>
                  <Typography>Contacts</Typography>
                  <Stack gap={1}>
                    {attachmentContacts?.map((contact) => (
                      <AttachmentContact
                        contactName={contact?.contactName}
                        contactPhone={contact?.contactPhone}
                        contactEmail={contact?.contactEmail}
                        key={`attachment-${contact?.contactName}`}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}

            {attachmentFiles?.length !== 0 && (
              <>
                <Stack gap={1}>
                  <Typography>Files</Typography>
                  <Stack gap={1}>
                    {attachmentFiles?.map((attachment) => (
                      <AttachmentFile
                        fileName={attachment?.fileName ?? ''}
                        url={attachment?.url ?? ''}
                        key={`attachment-${attachment?.fileName}`}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}

            {attachmentLinks?.length !== 0 && (
              <>
                <Stack gap={1}>
                  <Typography>Links</Typography>
                  <Stack gap={1}>
                    {attachmentLinks?.map((link, index) => (
                      <AttachmentLink
                        url={link?.url ?? ''}
                        key={`attachment-${link?.url}`}
                        lid={index + 1}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}

            {attachmentEngage?.length !== 0 && (
              <>
                <Stack gap={1}>
                  <Stack gap={1}>
                    {attachmentEngage?.map((attachment) =>
                      !isImage(attachment.fileName) ? (
                        <AttachmentFile
                          fileName={removePrefix(attachment?.fileName ?? '')}
                          url={attachment?.url ?? ''}
                          key={`attachment-${attachment?.fileName}`}
                        />
                      ) : (
                        <ContentThumbnailImage
                          name={attachment?.fileName}
                          imageUrl={attachment?.url}
                          sx={{
                            width: '100%',
                            marginBottom: '10px',
                          }}
                        />
                      ),
                    )}
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};
