import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NoticeDataType, NoticeItemType } from '../../types/notices.type';

interface NoticeState {
  noticePage: number;
  noticesData: Array<NoticeItemType>;
  hasNextPage: boolean;
}

const initialState: NoticeState = {
  noticePage: 1,
  noticesData: [],
  hasNextPage: false,
};

const slice = createSlice({
  name: 'notices',
  initialState,
  reducers: {
    setNoticePage(state: NoticeState) {
      state.noticePage = state.noticePage + 1;
    },
    setNoticesData(
      state: NoticeState,
      action: PayloadAction<Array<NoticeItemType>>,
    ) {
      state.noticesData = state?.noticesData.concat(action?.payload);
    },
    setHasNextPage(state: NoticeState, action: PayloadAction<boolean>) {
      state.hasNextPage = action?.payload;
    },
  },
});

export const { reducer } = slice;
export const { setHasNextPage, setNoticePage, setNoticesData } = slice.actions;
