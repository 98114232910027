import {
  Stack,
  Box,
  Typography,
  Hidden,
  IconButton,
  Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactNode, useState } from 'react';
import { Sidebar } from '../components/layout/sidebar';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { StudentList } from '../components/student-list';
import { useLocation } from 'react-router-dom';
import { ProfileMenu } from '../components/layout/profile-menu';
import { Myceetya } from '../modules/myceetya';
import { useGetSchoolTerms } from '../hooks/useGetSchoolTerms';

interface PageLayoutProps {
  activeRoute: string;
  children: ReactNode;
  parentSelect?: ReactNode;
}

const PageLayout = ({
  activeRoute,
  children,
  parentSelect,
}: PageLayoutProps) => {
  const [isMobileOpen, setMobileOpen] = useState(false);
  const { isSmallDevice, isMediumDevice, isLargeDevice } = useMediaQuery();

  const { pathname } = useLocation();

  const { schoolTerms } = useGetSchoolTerms();

  const routesWithNoSelector = ['/calender', '/profile'];
  const routesWithNoStudentListClear = [
    '/my-students',
    '/timetable',
    '/contacts',
  ];

  const hideSelector =
    routesWithNoSelector.includes(pathname) ||
    pathname.includes('dashboard') ||
    pathname.includes('school-information') ||
    pathname.includes('co-curricular') ||
    pathname.includes('calendar');

  const isSchoolInfoPage = pathname.includes('school-information');
  const hasStudentListClear = !routesWithNoStudentListClear.includes(pathname);

  return (
    <>
      <Stack flexDirection='column' width='100%' ml={{ xs: 0, sm: '220px' }}>
        <Stack
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          position='sticky'
          top='0'
          zIndex={10}
          bgcolor='#FFFFFF'
          px={3}
        >
          <Grid
            container
            columns={12}
            height='100%'
            justifyContent='space-between'
          >
            <Grid
              xs={6}
              sm={5}
              md={4}
              lg={2}
              item
              maxWidth={{ xs: 'max-content', sm: '274px' }}
            >
              <Stack flexDirection='row' alignItems='center' height='100%'>
                <Hidden smUp implementation='css'>
                  <IconButton
                    aria-label='Open drawer'
                    edge='start'
                    onClick={() => setMobileOpen(true)}
                    sx={{ color: '#1F3263' }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
                {!isSchoolInfoPage && (
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    color={{ xs: '#906d4f', sm: '#906d4f' }}
                    py={2}
                    data-tour={activeRoute}
                  >
                    {activeRoute}
                  </Typography>
                )}
              </Stack>
            </Grid>
            {!isSmallDevice && !isMediumDevice && !isLargeDevice && (
              <Grid
                xs={9}
                sm={10}
                md={6}
                item
                display='flex'
                justifyContent='center'
                alignContent='center'
                overflow='hidden'
                py={1}
              >
                <Stack
                  flexDirection='row'
                  justifyContent='center'
                  alignContent='center'
                  width='100%'
                  gap={2}
                >
                  <img
                    src='/images/MLC_OMNIA LOGO_1200x630.svg'
                    alt='MLC Omnia'
                    style={{
                      maxHeight: '60px',
                    }}
                  />
                </Stack>
              </Grid>
            )}

            {isSmallDevice || isMediumDevice || isLargeDevice ? (
              <Grid
                xs={3}
                sm={2}
                md={4}
                lg={2}
                item
                display='flex'
                justifyContent='center'
                alignItems='center'
                height='100%'
              >
                <img
                  src='/images/MLC_OMNIA LOGO_1200x630.svg'
                  alt='MLC Omnia'
                  style={{ maxHeight: '60px' }}
                />
              </Grid>
            ) : null}

            <Grid
              xs={2}
              sm={5}
              md={4}
              lg={2}
              item
              justifyContent='flex-end'
              height='100%'
            >
              <ProfileMenu />
            </Grid>
          </Grid>
        </Stack>

        {parentSelect}

        <>
          {!hideSelector ? (
            <Stack pt={2} width='100%' alignItems='center'>
              <StudentList hasClear={hasStudentListClear} />
            </Stack>
          ) : null}
        </>

        <Box p={3}>{children}</Box>
      </Stack>
      <Hidden smDown>
        <Sidebar open variant='permanent' onClose={() => null} />
      </Hidden>
      {isSmallDevice ? (
        <Sidebar
          open={isMobileOpen}
          variant='temporary'
          onClose={() => setMobileOpen(false)}
          width={320}
        />
      ) : (
        <Sidebar open variant='permanent' onClose={() => null} />
      )}
      <Myceetya isShown={schoolTerms?.showMYCEETYAForm} />
    </>
  );
};

export { PageLayout };
