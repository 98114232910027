import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  Typography,
  IconButton,
  styled,
  Stack,
  CircularProgress,
} from '@mui/material';
import { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface Props extends DialogProps {
  children: ReactNode;
  open: boolean;
  title?: string;
  subtitle?: string;
  handleClose: () => void;
  actions: JSX.Element | null;
  isLoading?: boolean;
}

const StyleDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    overflowY: 'visible',
    maxWidth: 'auto',
    width: '100%',
  },
}));

export const Dialog = ({
  children,
  open,
  title,
  subtitle,
  handleClose,
  actions,
  isLoading = false,
  ...props
}: Props) => {
  return (
    <StyleDialog
      open={open}
      onClose={handleClose}
      {...props}
      PaperProps={{
        'overflow-y': 'visible !important',
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          bgcolor: '#000000',
          position: 'absolute',
          width: 24,
          height: 24,
          p: 1,
          right: -12,
          top: -12,
          color: '#FFFFFF',
          '&:hover': {
            bgcolor: '#000000',
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      {title && (
        <DialogTitle bgcolor='primary' sx={{ bgcolor: 'primary' }}>
          <Typography fontSize={18} fontWeight={600}>
            {title}
          </Typography>
          {subtitle && <Typography>{subtitle}</Typography>}
        </DialogTitle>
      )}
      <DialogContent
        dividers
        sx={{
          borderBottom: actions ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
          paddingY: 0,
        }}
      >
        {isLoading ? (
          <Stack p={10} alignItems='center'>
            <CircularProgress size={40} sx={{ color: '#000000' }} />
          </Stack>
        ) : (
          children
        )}
      </DialogContent>
      {actions && !isLoading && <DialogActions>{actions}</DialogActions>}
    </StyleDialog>
  );
};
