import { Button, Stack, Tab, Tabs } from '@mui/material';
import { useMsalIdToken } from '../../hooks/useMsalIdToken';
import { useState, SyntheticEvent, useEffect, useMemo } from 'react';
import { StudentForm } from './components/student';
import { ParentForm } from './components/parent';
import {
  ParentMyceetyaDetailType,
  StudentMyceetyaDetailType,
} from '../../types/myceetya.type';
import { useMyceetya, useMyceetyaMutation } from './useMyceetya';
import { SummaryView } from './components/summary-view';
import { Dialog } from '../../components/dialog';
import { languages, countries } from './components/config';
import { ToastView } from '../../components/toast';

interface MyceetyaProps {
  isShown: boolean;
}

export const Myceetya = ({ isShown }: MyceetyaProps) => {
  const token = useMsalIdToken();
  const { currentMyceetya, isFetching, isLoading } = useMyceetya();

  const [isMyceetyaActive, setMyceetyaActive] = useState(false);
  const [currentView, setView] = useState<'default' | 'summary'>('default');
  const [tab, setTab] = useState(0);
  const [isValid, setValid] = useState(false);

  const createMyceetya = useMyceetyaMutation();

  const [myceetyaStudentDetails, setStudentValues] = useState<
    Array<StudentMyceetyaDetailType>
  >([]);

  const [myceetyaParentDetails, setParentValues] =
    useState<ParentMyceetyaDetailType | null>(null);

  const languageList = useMemo(() => {
    const list = [
      ...languages.concat(
        currentMyceetya?.myceetyaStudentDetails?.map(
          (item: { otherLanguageAtHome: string }) => item.otherLanguageAtHome,
        ),
      ),
    ];

    return [...new Set(list)];
  }, [currentMyceetya]);

  const countryList = useMemo(() => {
    const list = [
      ...countries.concat(
        currentMyceetya?.myceetyaStudentDetails?.map(
          (item: { countryOfBirth: string }) => {
            if (item?.countryOfBirth === null) {
              return;
            }

            return {
              name: item.countryOfBirth,
              code: item.countryOfBirth,
            };
          },
        ),
      ),
    ];

    return list.filter((value, index) => {
      if (!Number(list?.[index]?.name)) {
        return value;
      }
    });
  }, [currentMyceetya]);

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleSubmit = () => {
    const payload = {
      created: new Date().toISOString(),
      myceetyaStudentDetails: myceetyaStudentDetails.map((student) => {
        return {
          isAboriginalTorresStraitIslanderOrigin:
            student?.isAboriginalTorresStraitIslanderOrigin?.toString(),
          otherLanguageAtHome:
            student?.otherLanguageAtHome === 'Other'
              ? student?.otherLanguage
              : student?.otherLanguageAtHome,
          countryOfBirth:
            student?.countryOfBirth === '0000'
              ? student?.otherCountry
              : student?.countryOfBirth,
          studentId: student?.studentId,
        };
      }),
      myceetyaParentDetail: {
        otherLanguageAtHome:
          myceetyaParentDetails?.otherLanguageAtHome === 'Other'
            ? myceetyaParentDetails?.otherLanguage
            : myceetyaParentDetails?.otherLanguageAtHome,
        highestYearCompleted: myceetyaParentDetails?.highestYearCompleted,
        highestQualificationCompleted:
          myceetyaParentDetails?.highestQualificationCompleted,
        ocupationGroup: myceetyaParentDetails?.ocupationGroup,
      },
    };

    void createMyceetya.mutate(payload);
  };

  const TabView = () => {
    return (
      <Stack
        position='sticky'
        top='0'
        bgcolor='#FFFFFF'
        zIndex={1}
        width='100%'
      >
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label='Student' />
          <Tab label='Parent' />
        </Tabs>
      </Stack>
    );
  };

  useEffect(() => {
    if (currentMyceetya) {
      setStudentValues(currentMyceetya?.myceetyaStudentDetails);
      setParentValues(currentMyceetya?.myceetyaParentDetail);
    }
  }, [currentMyceetya]);

  useEffect(() => {
    if (createMyceetya?.isSuccess) {
      setMyceetyaActive(false);
    }
  }, [createMyceetya?.isSuccess]);

  useEffect(() => {
    if (isShown) {
      setMyceetyaActive(isShown);
    }
  }, [isShown]);

  useEffect(() => {
    /**
     * Validate student and parent details
     * check for any null values
     */
    if (myceetyaStudentDetails && myceetyaParentDetails) {
      const studentCheck =
        JSON.stringify(myceetyaStudentDetails).match(/:null[\},]/) === null;

      const parentCheck =
        myceetyaParentDetails?.highestQualificationCompleted !== null &&
        myceetyaParentDetails?.highestYearCompleted !== null &&
        myceetyaParentDetails?.ocupationGroup !== null &&
        myceetyaParentDetails?.otherLanguageAtHome !== null;

      setValid(parentCheck && studentCheck);
    }
  }, [myceetyaStudentDetails, myceetyaParentDetails]);

  return (
    <>
      <Dialog
        open={isMyceetyaActive}
        handleClose={() => setMyceetyaActive(false)}
        title='MYCEETYA'
        isLoading={isFetching || isLoading}
        actions={
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            mx={1}
            py={2}
            width='100%'
          >
            <Button
              onClick={() =>
                currentView === 'default'
                  ? setMyceetyaActive(false)
                  : setView('default')
              }
              variant='contained'
              color='inherit'
              disabled={createMyceetya?.isPending}
            >
              {currentView === 'default' ? 'Cancel' : 'Back'}
            </Button>
            <Button
              onClick={() =>
                currentView === 'default' ? setView('summary') : handleSubmit()
              }
              variant='contained'
              disabled={createMyceetya?.isPending || !isValid}
            >
              {currentView === 'default'
                ? 'Next'
                : createMyceetya?.isPending
                ? 'Submitting...'
                : 'Submit'}
            </Button>
          </Stack>
        }
      >
        <Stack width='100%'>
          <>
            {currentView === 'default' ? (
              <Stack>
                <Stack alignItems='center' justifyContent='center'>
                  <TabView />
                  <Stack width='100%'>
                    <Stack display={tab === 0 ? 'flex' : 'none'}>
                      <StudentForm
                        currentDetail={currentMyceetya?.myceetyaStudentDetails}
                        myceetyaStudentDetails={myceetyaStudentDetails}
                        setStudentValues={setStudentValues}
                        languages={languageList}
                        countries={countryList}
                      />
                    </Stack>
                    <Stack display={tab === 1 ? 'flex' : 'none'}>
                      {myceetyaParentDetails && (
                        <ParentForm
                          currentDetail={currentMyceetya?.myceetyaParentDetail}
                          myceetyaParentDetails={myceetyaParentDetails}
                          setParentValues={setParentValues}
                        />
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <Stack>
                <SummaryView
                  details={{
                    students: myceetyaStudentDetails,
                    parent: myceetyaParentDetails,
                  }}
                />
              </Stack>
            )}
          </>
        </Stack>
      </Dialog>
      <ToastView
        isOpen={createMyceetya.isSuccess}
        type='success'
        message='Successfully saved the MYCEETYA information.'
      />
    </>
  );
};
