import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const useMsalIdToken = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const getToken = async () => {
    const request = {
      scopes: ['openid'],
      account: accounts[0],
    };

    await instance
      .acquireTokenSilent(request)
      .then((responseData) => setAccessToken(responseData.accessToken))
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(request).then((responseError) => {
            return responseError;
          });
        }
      });
  };

  useEffect(() => {
    if (inProgress === 'none') {
      void getToken();
    }
  }, [inProgress]);

  useEffect(() => {
    if (accessToken === null) {
      void getToken();
      return;
    }
  }, [accessToken]);

  return accessToken;
};
