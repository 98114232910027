import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  children: ReactNode;
}

const DialogWrapper = ({ onClose, isOpen, children }: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'visible',
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          bgcolor: '#000000',
          position: 'absolute',
          width: 24,
          height: 24,
          p: 1,
          right: -12,
          top: -12,
          color: '#FFFFFF',
          '&:hover': {
            bgcolor: '#000000',
          },
        }}
      >
        <CloseIcon />
      </IconButton>

      {children}
    </Dialog>
  );
};

export { DialogWrapper };
