import { Stack, Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export const SubscribedTagsEmpty = ({ children }: Props) => {
  return (
    <Stack p={3} justifyContent='center' alignItems='center'>
      <Typography
        variant='body1'
        color='black'
        textAlign='center'
        fontWeight={600}
      >
        You are not subscribed to any Tags.
      </Typography>
      {children}
    </Stack>
  );
};
