import axios from 'axios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { getAppConfig } from '../../../appConfig';
import { useAppSelector } from '../../../state/stores/root.store';

export const useNotices = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent } = useAppSelector((state) => state.adminParent);

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['notices', `notices-${activeParent?.email}`],
    enabled: !!token,
    refetchOnWindowFocus: false,
    queryFn: async ({ pageParam }) => {
      const res = await axios.get(
        `${appConfig.app
          .baseUrl!}notice/getcombinednotices?page=${pageParam}&itemsperpage=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
        },
      );
      return res.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage?.hasNextPage) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });

  return {
    notices: data,
    error,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};
