import { Button, DialogActions, DialogContent, Stack } from '@mui/material';
import { ApprovedMedications } from '../cards/approved-medications';
import { Allergies } from '../cards/allergies';
import { Ailments } from '../cards/ailments';
import { ReactNode, useContext } from 'react';
import { useMedicalInfo } from '../useMedicalInformation';
import { CloseInfo } from '../close';

interface ViewDetailsProps {
  children: ReactNode;
}

const InfoView = ({ children }: ViewDetailsProps) => {
  const { data, handleView, handleClose } = useMedicalInfo();

  return (
    <>
      <DialogContent>
        <CloseInfo close={handleClose} />
        {children}
        <Stack py={3} gap={3}>
          <ApprovedMedications items={data?.medications ?? []} />
          <Allergies items={data?.allergies ?? []} />
          <Ailments items={data?.ailments ?? []} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'flex-end' }}>
        <Button onClick={() => handleView('edit')} variant='contained'>
          Edit
        </Button>
      </DialogActions>
    </>
  );
};

export { InfoView };
