import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PupilType } from '../../types/students.type';

interface UserState {
  currentStudent: PupilType | null;
}

const initialState: UserState = {
  currentStudent: null,
};

const slice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    setCurrentStudent(
      state: UserState,
      action: PayloadAction<PupilType | null>,
    ) {
      state.currentStudent = action?.payload;
    },
    resetCurrentStudent(state: UserState) {
      state.currentStudent = null;
    },
  },
});

export const { reducer } = slice;
export const { setCurrentStudent, resetCurrentStudent } = slice.actions;
