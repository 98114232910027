import { Snackbar, Alert } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  isOpen: boolean;
  status: 'success' | 'error';
  message: string;
}

const StatusMessage = ({ isOpen, status, message }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert severity={status} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default StatusMessage;
