import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface InformationValueProps {
  infoName: string | ReactNode;
  infoValue?: string | number | ReactNode;
  children?: ReactNode;
  fontSize?: number;
  infoAttributes?: object;
}

export const InformationValue = ({
  infoName,
  infoValue = '',
  children,
  fontSize = 14,
  infoAttributes,
}: InformationValueProps) => {
  return (
    <Stack
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      {...infoAttributes}
    >
      <Box>
        {typeof infoName === 'string' ? (
          <Typography fontSize={fontSize} fontWeight={300}>
            {infoName}
          </Typography>
        ) : (
          infoName
        )}
      </Box>
      <Stack alignItems='flex-end'>
        {typeof infoValue === 'string' || typeof infoValue === 'number' ? (
          <Typography fontSize={fontSize} fontWeight={600}>
            {infoValue}
          </Typography>
        ) : (
          infoValue
        )}
        {children}
      </Stack>
    </Stack>
  );
};
