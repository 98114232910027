import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { EventClickArg } from '@fullcalendar/core';
import React from 'react';

interface Props {
  activeEvent: EventClickArg | null;
}

const ContentDigistorm = ({ activeEvent }: Props) => {
  return (
    <Stack gap={2}>
      <Stack>
        <Typography fontSize={16} fontWeight={600}>
          {activeEvent?.event.title}
        </Typography>
        <Typography fontSize={11}>
          {activeEvent?.event.start &&
            format(new Date(activeEvent?.event?.start), 'EEEE dd MMMM yyyy')}
        </Typography>
        <Typography fontSize={11}>
          {activeEvent?.event.start &&
            format(new Date(activeEvent?.event?.start), 'HH:mm')}
          {activeEvent?.event.end && (
            <React.Fragment>
              {` - `}
              {format(new Date(activeEvent?.event?.end), 'HH:mm')}
            </React.Fragment>
          )}
        </Typography>
        <Typography fontSize={11}>
          {activeEvent?.event?.extendedProps?.location}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { ContentDigistorm };
