import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Fragment, useEffect, useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../state/stores/root.store';
import { setCurrentStudent } from '../../state/slices/student.slice';
import { PupilType } from '../../types/students.type';
import { StudentAvatar } from './student-avatar';
import { useStudents } from '../../hooks/useStudents';

const StudentList = ({ hasClear = true }: { hasClear?: boolean }) => {
  const dispatch = useAppDispatch();

  const { data: pupils, isFetching } = useStudents();

  const [sortedPupils, setSortedPupils] = useState<Array<PupilType>>([]);

  const { currentStudent } = useAppSelector((state) => state.student);
  const { isAdmin, hasParent } = useAppSelector((state) => state.adminParent);

  const handleSelectStudent = (pupil: PupilType | null) => {
    dispatch(setCurrentStudent(pupil));
  };

  useEffect(() => {
    if (pupils) {
      if (pupils?.length === 1) {
        dispatch(setCurrentStudent(pupils?.[0]));
      } else {
        const groupedYearSchool = pupils
          ?.filter(
            (pupil: PupilType) =>
              pupil.yearGroupCode !== '013' && pupil.yearGroupCode !== '014',
          )
          ?.slice()
          .sort((first: PupilType, second: PupilType) => {
            return (
              Number(second?.yearGroup?.split(' ')?.[1]) -
              Number(first?.yearGroup?.split(' ')?.[1])
            );
          }) as Array<PupilType>;

        const groupedPreYearSchool = pupils
          ?.filter(
            (pupil: PupilType) =>
              pupil.yearGroupCode === '013' || pupil.yearGroupCode === '014',
          )
          ?.slice()
          .sort((first: PupilType, second: PupilType) => {
            return Number(first?.yearGroupCode) - Number(second?.yearGroupCode);
          }) as Array<PupilType>;

        if (!currentStudent) {
          dispatch(setCurrentStudent(groupedYearSchool?.[0]));
        }
        setSortedPupils([...groupedYearSchool, ...groupedPreYearSchool]);
      }
    }
  }, [pupils]);

  if (isAdmin && !hasParent) {
    return null;
  }

  return (
    <Box
      display='flex'
      height='100%'
      width={{ xs: '100vw', sm: 'auto' }}
      data-tour='students'
    >
      {isFetching ? (
        <small>Loading...</small>
      ) : (
        <Fragment>
          <Box
            display='flex'
            flexDirection='row'
            gap={1}
            overflow='auto'
            px={{ xs: 3, lg: 0 }}
          >
            {sortedPupils &&
              sortedPupils?.map((pupil: PupilType, index: number) => {
                const isActive = currentStudent?.pupilId === pupil?.pupilId;

                if (!pupil) {
                  return null;
                }

                return (
                  <Stack
                    key={`avatar-pill-${index}`}
                    sx={{
                      border: isActive
                        ? '1px solid rgb(124, 159, 191)'
                        : '1px solid',
                      borderRadius: '8px',
                      backgroundColor: isActive
                        ? 'rgb(124, 159, 191)'
                        : '#FFFFFF',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      flex: '0 0 auto',
                      gap: 1,
                      py: 0.5,
                      px: 1,
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                    component='button'
                    onClick={() => handleSelectStudent(pupil)}
                  >
                    <StudentAvatar
                      pupilId={pupil?.pupilId}
                      fullName={`${pupil?.forename} ${pupil?.surname}` ?? ''}
                      acronym={pupil?.avatarAcronym ?? ''}
                    />
                    <Box
                      textAlign='start'
                      color={isActive ? '#FFFFFF' : '#1F3263'}
                    >
                      <Typography noWrap={true} sx={{ fontSize: '12px' }}>
                        {pupil?.forename}
                      </Typography>
                      <Typography sx={{ fontSize: '11px' }}>
                        {pupil?.yearGroup}
                      </Typography>
                    </Box>

                    {hasClear && (
                      <Box>
                        {currentStudent?.pupilId === pupil?.pupilId ? (
                          <IconButton
                            sx={{
                              color: '#FFFFFF',
                              position: 'relative',
                              zIndex: 10,
                              py: 0,
                            }}
                            tabIndex={1}
                            onClick={() => {
                              setTimeout(() => {
                                dispatch(setCurrentStudent(null));
                              }, 10);
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    )}
                  </Stack>
                );
              })}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export { StudentList };
