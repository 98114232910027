import { Snackbar, Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../state/stores/root.store';

interface Props {
  isOpen: boolean;
}

const ErrorView = ({ isOpen }: Props) => {
  const [open, setOpen] = useState(false);
  const { isAdmin } = useAppSelector((state) => state.adminParent);
  const errorMessage = isAdmin
    ? `Action not performed, you are not authorised to perform this action' but this should only display as an error for a user who is not authorised to perform an action`
    : `Error updating your profile. Please try again.`;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity='error' onClose={handleClose}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export { ErrorView };
