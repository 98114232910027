import { Link, Typography } from '@mui/material';

interface Props {
  url: string;
  lid: number;
}

const AttachmentLink = ({ url, lid }: Props) => {
  return (
    <Link target='_blank' href={url}>
      <Typography>Notice Link {lid}</Typography>
    </Link>
  );
};

export { AttachmentLink };
