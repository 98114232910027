import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAppConfig } from '../appConfig';
import { EventItemType } from '../types/events.type';
import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
const appConfig = getAppConfig();

type RsvpPostType = {
  data: null | string;
};

export const api = createApi({
  reducerPath: 'events',
  baseQuery: fetchBaseQuery({ baseUrl: appConfig.app.baseUrl! }),
  endpoints: (build) => ({
    getEvents: build.query({
      query: ({ token, page }) => ({
        url: 'event/getevents',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          itemsperpage: 10,
        },
      }),
    }),
    getDashboardEvents: build.query({
      query: ({ token, page }) => ({
        url: 'umbraco/getevents',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          itemsperpage: 1000,
        },
      }),
    }),
    createRsvp: build.mutation({
      query: ({ token, body }) => ({
        url: 'event/submitform',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          firstname: body.parent?.forename,
          surname: body.parent?.surname,
          phone: body.parent?.telephoneNumbers[0]?.phoneNumber || '',
          mobile: body.parent?.telephoneNumbers[0]?.phoneNumber || '',
          additionalRSVP: body.formData?.numberOfAditionalRSVP,
          accessibleSeatingRequired: body.formData?.accessibleSeatingRequired,
          department: body.formData?.department,
          dietaryRequirements: body.formData?.dietaryRequirements,
          earlyDismissal:
            body.formData?.earlyDismissal === 'YES' ? true : false,
          isPermitted: body.formData?.earlyDismissal === 'YES' ? true : false,
          signer: body.formData?.signer,
          childrenIds:
            body.formData?.students && body.formData?.students.join(','),
          eventId: body.eventId,
        },
      }),
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetDashboardEventsQuery,
  useCreateRsvpMutation,
} = api;
