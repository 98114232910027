import { Breadcrumbs, Typography } from '@mui/material';

interface BreadcrumbProps {
  category: string;
  link: string;
  title?: string;
}

const Breadcrumb = ({
  category,
  link,
  title = 'School Information',
}: BreadcrumbProps) => {
  return (
    <Breadcrumbs sx={{ pb: 2 }}>
      <Typography>{title}</Typography>
      {category && (
        <Typography textTransform='capitalize'>
          {category?.replace(/-/g, ' ')}
        </Typography>
      )}
      {link && (
        <Typography textTransform='capitalize' fontWeight={600}>
          {link?.replace(/-/g, ' ')}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export { Breadcrumb };
