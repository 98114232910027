import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from './useMsalIdToken';
import { getAppConfig } from '../appConfig';
import { useAppSelector } from '../state/stores/root.store';

export const useSchoolContacts = ({ pupilId }: { pupilId: string }) => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent, isAdmin } = useAppSelector(
    (state) => state.adminParent,
  );
  const enabled = isAdmin
    ? !!token && !!pupilId && !!activeParent?.email
    : !!token && !!pupilId;

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      'school-contacts',
      `school-contacts-${pupilId}`,
      activeParent?.email,
    ],
    enabled,
    queryFn: async () => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}pupil/getschoolcontacts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
          params: {
            pupilId,
          },
        },
      );
      return res.data;
    },
  });

  return {
    data,
    error,
    isFetching,
  };
};
