import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../hooks/useMsalIdToken';
import { getAppConfig } from '../../appConfig';

export const useGetPhoto = (pupilId: string) => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['photo', pupilId],
    enabled: !!token,
    queryFn: async () => {
      const response = await fetch(
        `${appConfig.app.baseUrl!}pupil/getstudentphoto?pupilID=${pupilId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((responseData) => responseData.blob())
        .then((dataBlob) => {
          const objectURL = URL.createObjectURL(dataBlob);
          return objectURL;
        });
      return response;
    },
  });

  return {
    photo: data,
    error,
    isFetching,
  };
};
