import { Button, Typography, TableRow, TableCell } from '@mui/material';
import { EventUmbracoItemType } from '../../../types/events.type';
import { useEventDate, useEventDateShortMonth } from './useEventDate';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface EventItemProps {
  event: EventUmbracoItemType;
  handleSelect: () => void;
}

export const EventItem = ({ event, handleSelect }: EventItemProps) => {
  const { startAt, endAt, enableRSVP, submittedSVP, eventLinkUrl } = event;
  const { isSmallDevice } = useMediaQuery();
  const { displayDate } = useEventDate(String(startAt), String(endAt));
  const { displayDateShortMonth } = useEventDateShortMonth(
    String(startAt),
    String(endAt),
  );
  const getActionText = () => {
    if (enableRSVP && !submittedSVP) {
      return 'RSVP';
    } else if (enableRSVP && submittedSVP) {
      return 'Attending';
    } else if (!enableRSVP && eventLinkUrl) {
      return 'Tickets';
    }
    return '';
  };

  const actionText = getActionText();

  const commonButtonStyle = {
    opacity: '100%',
    borderRadius: '5px',
    width: '96px',
    height: '26px',
    textTransform: 'capitalize',
  };

  const buttonStyle = {
    Tickets: {
      ...commonButtonStyle,
      bgcolor: '#FFFFFF',
      color: '#916D4F',
      border: '1px solid #916D4F',
    },
    Attending: {
      ...commonButtonStyle,
      bgcolor: '#1F3263',
      color: '#FFFFFF',
    },
    RSVP: {
      ...commonButtonStyle,
      bgcolor: '#916D4F',
      color: '#FFFFFF',
    },
  };

  const typographyStyle = {
    fontWeight: 'regular',
    fontSize: 13,
  };

  const tableCellStyle = {
    px: 0,
    py: 1,
  };

  return (
    <TableRow
      sx={{
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        py: 1,
      }}
      onClick={handleSelect}
    >
      <TableCell
        align='left'
        sx={{
          ...tableCellStyle,
          width: isSmallDevice ? '60px' : 'auto',
        }}
      >
        <Typography sx={typographyStyle}>
          {!isSmallDevice ? displayDate : displayDateShortMonth}
        </Typography>
      </TableCell>
      <TableCell
        align='left'
        sx={{
          ...tableCellStyle,
          width: isSmallDevice ? '150px' : 'auto',
        }}
      >
        <Typography sx={typographyStyle}>{event?.name}</Typography>
      </TableCell>
      <TableCell align='center' sx={tableCellStyle}>
        {actionText && (
          <Button variant='contained' sx={buttonStyle[actionText]}>
            {actionText}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
