import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from './useMsalIdToken';
import { getAppConfig } from '../appConfig';

export const useGetSchoolTerms = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { data, error, isFetching, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['setting-schoolterms'],
    enabled: !!token,
    queryFn: async () => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}setting/getschoolterms`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return res.data;
    },
  });

  return {
    schoolTerms: data,
    isLoading,
  };
};
