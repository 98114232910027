import {
  Stack,
  Select,
  SelectChangeEvent,
  Box,
  Chip,
  Button,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { ContentCard } from '../../../../../../components/layout/ContentCard';

interface ContentSelectProps {
  menu: ReactNode;
  isOpen: boolean;
  items: Array<string>;
  title: string;
  toggle: (value: boolean) => void;
  setItems: (value: Array<string>) => void;
  children: ReactNode;
}

const ContentSelect = ({
  menu,
  isOpen,
  items,
  title,
  toggle,
  setItems,
  children,
}: ContentSelectProps) => {
  const Content = () => {
    return (
      <Stack p={2} gap={4}>
        <Select
          multiple
          open={isOpen}
          value={items}
          onChange={(event: SelectChangeEvent<typeof items>) => {
            return setItems(
              typeof event?.target?.value === 'string'
                ? event?.target?.value.split(',')
                : event?.target?.value,
            );
          }}
          onClose={() => toggle(false)}
          onOpen={() => toggle(true)}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: string) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          sx={{
            position: 'relative',
          }}
        >
          {menu}
          <Stack
            alignItems='flex-end'
            py={2}
            borderTop='1px solid #c1c1c1'
            bgcolor='#FFFFFF'
            width='100%'
          >
            <Button
              variant='contained'
              color='primary'
              sx={{ maxWidth: 120, mx: 2 }}
              onClick={() => toggle(false)}
            >
              <Typography>Submit</Typography>
            </Button>
          </Stack>
        </Select>
        {children}
      </Stack>
    );
  };

  return (
    <ContentCard
      sx={{
        border: '1px solid #EBDFDF',
        minWidth: { xs: '280px', md: '500px' },
      }}
      title={title}
      headerCta={
        <Stack
          alignItems='center'
          justifyContent='center'
          borderRadius='100%'
          border='1px solid #7C9FBF'
          color='#1F3263'
          width={20}
          height={20}
        >
          <Typography fontSize={12}>{items?.length}</Typography>
        </Stack>
      }
      content={Content()}
    />
  );
};

export { ContentSelect };
