import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getAppConfig } from '../../../appConfig';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { useAppSelector } from '../../../state/stores/root.store';

export const useStaffAdmin = ({ query }: { query: string }) => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent } = useAppSelector((state) => state.adminParent);

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['staff-admin', query],
    enabled: !!token && !!query,
    queryFn: async () => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}staffadmin/getparents/${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
        },
      );
      return res.data;
    },
  });

  return {
    data,
    error,
    isFetching,
  };
};
