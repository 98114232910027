import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import InfoIcon from '@mui/icons-material/Info';
import { PATHS } from '../types';

export const navigationMenu = [
  {
    label: 'Dashboard',
    Icon: DashboardIcon,
    path: PATHS.DASHBOARD,
    external: false,
  },
  {
    label: 'My Students',
    Icon: PeopleIcon,
    path: PATHS.MY_STUDENTS,
    external: false,
  },
  {
    label: 'Timetable',
    Icon: CalendarViewMonthIcon,
    path: PATHS.TIMETABLE,
    external: false,
  },
  {
    label: 'School Contacts',
    Icon: ImportContactsIcon,
    path: PATHS.SCHOOL_CONTACTS,
    external: false,
  },
  {
    label: 'School Calendar',
    Icon: CalendarMonthIcon,
    path: PATHS.SCHOOL_CALENDAR,
    external: false,
  },
  {
    label: 'School Information',
    Icon: InfoIcon,
    path: PATHS.SCHOOL_INFORMATION,
    external: false,
  },
  {
    label: 'Audacia',
    Icon: NewspaperOutlinedIcon,
    path: 'https://www.thebuzz.net.au/mlc/newsletter/',
    external: true,
  },
  {
    label: 'Absences',
    Icon: AssignmentTurnedInOutlinedIcon,
    path: 'https://forms.office.com/Pages/ResponsePage.aspx?id=2FB9Kf3lC0-Dewb2YR188dM66eeNxBZHr61iOf4RoQBUNEFVWjI1WktITjZSUE1LRFQwNFFTQlFKSS4u',
    external: true,
  },
  {
    label: 'My Profile',
    Icon: InfoIcon,
    path: PATHS.PROFILE,
    external: false,
  },
  {
    label: 'Take a Tour',
    Icon: InfoIcon,
    path: PATHS.TAKEATOUR,
    external: false,
  },
];
