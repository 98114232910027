import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FormTextField } from '../../../../components/form';
import { useEffect, useState } from 'react';
import { useMsalIdToken } from '../../../../hooks/useMsalIdToken';
import { useUpdateParentProfileMutation } from '../../../../services/parent.service';
import { SuccessView } from './success';
import { ErrorView } from './error';
import { schema } from './schema';
import { Dialog } from '../../../../components/dialog';

interface Props {
  values: {
    forename: string;
    surname: string;
    email: string;
    mobile: string;
    work: string;
    home: string;
  };
  isOpen: boolean;
  handleClose: () => void;
}

const FormContactInformation = ({ values, isOpen, handleClose }: Props) => {
  const token = useMsalIdToken();

  const [
    updateParentProfile,
    {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      reset: resetMutation,
    },
  ] = useUpdateParentProfileMutation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      forename: '',
      surname: '',
      email: '',
      mobile: '',
      work: '',
      home: '',
    },
  });

  const onSubmit = async (data: any) => {
    void updateParentProfile({ token, payload: data });
  };

  const handleContactInfo = (
    type: 'mobile' | 'work' | 'home',
    value: string,
  ) => {
    const formattedValue = value.replace(/[^0-9]/g, '');
    return setValue(type, formattedValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    reset(values);
  }, [values]);

  useEffect(() => {
    if (!isOpen) {
      // Add delay to remove view flicker on state update
      setTimeout(() => {
        resetMutation();
      }, 1000);
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title={!isUpdateSuccess ? 'Edit Information' : ''}
      actions={
        isUpdateSuccess ? null : (
          <Stack
            flex={1}
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            py={1}
            px={3}
          >
            <Button onClick={handleClose} variant='outlined'>
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              form='profile-edit-contact'
              disabled={isUpdating || !isDirty || !isValid}
            >
              {isUpdating ? 'Saving...' : 'Save'}
            </Button>
          </Stack>
        )
      }
    >
      {isUpdateSuccess ? (
        <SuccessView />
      ) : (
        <Stack gap={2} py={3} minWidth={{ xs: 300, md: 500 }} maxWidth={600}>
          <Stack overflow='auto' height='100%' paddingTop={1}>
            <form
              id='profile-edit-contact'
              onSubmit={(event) => {
                event.preventDefault();
                void handleSubmit(onSubmit)(event);
              }}
            >
              <Stack gap={2}>
                <Stack gap={1}>
                  <Controller
                    name='forename'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormTextField
                        {...field}
                        error={Boolean(errors?.forename?.message)}
                        label='Forename'
                      />
                    )}
                  />
                  <Typography color='error' fontSize={12}>
                    {errors?.forename?.message}
                  </Typography>
                </Stack>

                <Stack gap={1}>
                  <Controller
                    name='surname'
                    control={control}
                    render={({ field }) => (
                      <FormTextField
                        {...field}
                        error={Boolean(errors?.surname?.message)}
                        label='Surname'
                      />
                    )}
                  />
                  <Typography color='error' fontSize={12}>
                    {errors?.surname?.message}
                  </Typography>
                </Stack>

                <Stack gap={1}>
                  <Controller
                    name='email'
                    control={control}
                    render={({ field }) => (
                      <FormTextField
                        {...field}
                        error={Boolean(errors?.email?.message)}
                        label='Email'
                      />
                    )}
                  />
                  <Typography color='error' fontSize={12}>
                    {errors?.email?.message}
                  </Typography>
                </Stack>
              </Stack>

              <Stack>
                <Box pt={3} pb={1}>
                  <Typography fontWeight={600}>Phone Numbers</Typography>
                </Box>
                <Stack gap={2}>
                  <Stack gap={1}>
                    <Controller
                      name='mobile'
                      control={control}
                      render={({ field }) => (
                        <FormTextField
                          {...field}
                          error={Boolean(errors?.mobile?.message)}
                          label='Mobile Number'
                          type='tel'
                          onChange={(event) =>
                            handleContactInfo('mobile', event?.target?.value)
                          }
                        />
                      )}
                    />
                    <Typography color='error' fontSize={12}>
                      {errors?.mobile?.message}
                    </Typography>
                  </Stack>

                  <Stack gap={1}>
                    <Controller
                      name='work'
                      control={control}
                      render={({ field }) => (
                        <FormTextField
                          {...field}
                          error={Boolean(errors?.work?.message)}
                          label='Work Number'
                          onChange={(event) =>
                            handleContactInfo('work', event?.target?.value)
                          }
                        />
                      )}
                    />
                    <Typography color='error' fontSize={12}>
                      {errors?.work?.message}
                    </Typography>
                  </Stack>

                  <Stack gap={1}>
                    <Controller
                      name='home'
                      control={control}
                      render={({ field }) => (
                        <FormTextField
                          {...field}
                          error={Boolean(errors?.home?.message)}
                          label='Home Number'
                          onChange={(event) =>
                            handleContactInfo('home', event?.target?.value)
                          }
                        />
                      )}
                    />
                    <Typography color='error' fontSize={12}>
                      {errors?.home?.message}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <ErrorView isOpen={isUpdateError} />
            </form>
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
};

export { FormContactInformation };
