import {
  FormControl,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';

const FormInput = ({ sx, ...props }: OutlinedInputProps) => {
  return (
    <FormControl sx={sx} variant='outlined' disabled={props?.disabled}>
      <InputLabel htmlFor={props.id}>{props?.label}</InputLabel>
      <OutlinedInput id={props?.id} {...props} />
    </FormControl>
  );
};

export { FormInput };
