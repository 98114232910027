export const replaceCmsLinkToHtmlLink = (body: string) => {
  if (body === null || body === undefined) {
    return '';
  }

  // Double link for same content
  const regexLinkWithText =
    /(?:\[link (target=new )?href=([^\]]+)\]([^\[]+)\[\/link\])/gm;
  let match;

  while ((match = regexLinkWithText.exec(body)) !== null) {
    const targetAttr = match[1];
    const href = match[2];
    const text = match[3];

    const replacement = `<a ${
      targetAttr?.length ? 'target="_blank"' : ''
    } href="${href}">${text}</a>`;
    body = body.replace(match[0], replacement);
  }

  // Simple Link
  const regexSimpleLink = /(?:\[link (target=new )?href=([^\]]+)\])/gm;

  while ((match = regexSimpleLink.exec(body)) !== null) {
    const targetAttr = match[1];
    const href = match[2];

    const replacement = `<a ${
      targetAttr?.length ? 'target="_blank"' : ''
    } href="${href}">${href}</a>`;
    body = body.replace(match[0], replacement);
  }

  return body;
};
