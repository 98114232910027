import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useMedicalInfo } from '../useMedicalInformation';
import { ApprovedMedications } from '../cards/approved-medications';
import { Allergies } from '../cards/allergies';
import { Ailments } from '../cards/ailments';
import { CloseInfo } from '../close';

interface SummaryProps {
  children?: ReactNode;
}

const SummaryView = ({ children }: SummaryProps) => {
  const {
    handleView,
    handleSave,
    setConfirmClose,
    medications,
    allergies,
    ailments,
    isUpdating,
    isUpdateSuccess,
  } = useMedicalInfo();

  useEffect(() => {
    if (isUpdateSuccess) {
      handleView('success');
    }
  }, [isUpdateSuccess]);

  return (
    <>
      <CloseInfo
        close={() => {
          setConfirmClose(true);
          handleView('confirm');
        }}
      />
      <DialogContent>
        <Stack gap={2}>
          {children}
          <Typography fontSize={18} fontWeight={700} textAlign='center'>
            Summary
          </Typography>
          <ApprovedMedications items={medications ?? []} />
          <Allergies items={allergies ?? []} />
          <Ailments items={ailments ?? []} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button
          color='inherit'
          onClick={() => handleView('edit')}
          variant='contained'
        >
          Back
        </Button>
        <Button onClick={handleSave} variant='contained' disabled={isUpdating}>
          {isUpdating ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </>
  );
};

export { SummaryView };
