import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ParentType = {
  contactId: string;
  email: string;
  fullname: string;
};

interface EventState {
  isAdmin: boolean | null;
  parentEmail: string | null;
  hasParent: boolean | null;
  activeParent: ParentType | null;
}

const initialState: EventState = {
  isAdmin: false,
  parentEmail: null,
  hasParent: false,
  activeParent: null,
};

const slice = createSlice({
  name: 'adminParent',
  initialState,
  reducers: {
    setAdminParent(state: EventState, action: PayloadAction<ParentType>) {
      state.activeParent = action.payload;
      state.hasParent = true;
    },
    setIsAdmin(state: EventState, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload;
    },
    refreshAdmin(state: EventState) {
      state.activeParent = null;
      state.hasParent = false;
    },
    resetParentAdmin(state: EventState) {
      state.isAdmin = false;
      state.hasParent = false;
      state.parentEmail = null;
      state.activeParent = null;
    },
  },
});

export const { reducer } = slice;
export const { setAdminParent, setIsAdmin, resetParentAdmin, refreshAdmin } =
  slice.actions;
