import { Box, Button, Stack, Typography } from '@mui/material';
import { ParentItemType } from '../../types/parent.type';
import { ContentCard } from '../../components/layout/ContentCard';
import { useState } from 'react';
import { EditAddress } from './form/edit-address';

interface Props {
  info: ParentItemType;
}

export const ProfileAddresses = ({ info }: Props) => {
  const [isFormOpen, toggleForm] = useState(false);

  const Content = () => {
    return (
      <Box maxHeight='calc(calc(100vh - 80px) / 2.6)' overflow='auto' px={2}>
        <Stack gap={3} py={2}>
          <Stack gap={1}>
            <Box>
              <Typography fontSize={14} fontWeight={600}>
                Residential address
              </Typography>
            </Box>
            <Stack gap={0.5}>
              <Typography fontWeight={300}>{`${info?.subdwelling ?? '-'} ${
                info?.dwelling ?? '-'
              }`}</Typography>
              <Typography fontWeight={300}>{info?.street ?? '-'}</Typography>
              <Typography fontWeight={300}>{info?.postcode ?? '-'}</Typography>
              <Typography fontWeight={300}>
                {info?.countryAddress ?? '-'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <EditAddress
          currentResidentialAddress={{
            street: `${info?.subdwelling} ${info?.dwelling}`,
            suburb: info?.street,
            zipCode: info?.postcode,
            country: info?.countryAddress,
            state: info?.state,
          }}
          isOpen={isFormOpen}
          handleClose={() => toggleForm(false)}
        />
      </Box>
    );
  };

  return (
    <ContentCard
      title='Address'
      content={<Content />}
      headerCta={
        <Button
          variant='text'
          sx={{ display: 'flex', gap: 1 }}
          onClick={() => toggleForm(true)}
        >
          <Typography color='primary' fontWeight={300} fontSize={12}>
            Edit Information
          </Typography>
        </Button>
      }
    />
  );
};
