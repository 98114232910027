import { Stack, Box, Typography } from '@mui/material';
import { ContentCard } from '../../../../components/layout/ContentCard';
import { useMedicalInfo } from '../useMedicalInformation';

interface Props {
  items: Array<string>;
}

const Ailments = ({ items }: Props) => {
  const { data, other } = useMedicalInfo();
  const Content = () => {
    return (
      <Stack p={2}>
        {items.length === 0 ? (
          <Box>
            <Typography fontSize={14}>
              No Diagnosed Medical Condition Recorded
            </Typography>
          </Box>
        ) : (
          <>
            {items?.map((item, index) => {
              if (
                item?.includes('Other Mental Health') ||
                item?.includes('Anaphylaxis - Other') ||
                item?.includes('Other Ailment')
              ) {
                return (
                  <Box key={item}>
                    <Typography fontSize={14} color='limegreen'>
                      {`${index + 1}. ${item} - ${other?.otherAilmentsText}`}
                    </Typography>
                  </Box>
                );
              }

              if (data?.ailments?.includes(item)) {
                return (
                  <Box key={item}>
                    <Typography fontSize={14}>
                      {`${index + 1}. ${item}`}
                    </Typography>
                  </Box>
                );
              }

              return (
                <Box key={item}>
                  <Typography fontSize={14} color='limegreen'>
                    {`${index + 1}. ${item}`}
                  </Typography>
                </Box>
              );
            })}
          </>
        )}
      </Stack>
    );
  };

  return (
    <ContentCard
      sx={{
        border: '1px solid #EBDFDF',
        minWidth: { xs: '280px', md: '500px' },
      }}
      title='Diagnosed Medical Conditions'
      content={<Content />}
    />
  );
};

export { Ailments };
