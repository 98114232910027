import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from './useMsalIdToken';
import { getAppConfig } from '../appConfig';
import { useAppSelector } from '../state/stores/root.store';
import { EventItemType } from '../types/events.type';

export const useEventsByRange = ({
  dates,
}: {
  dates: { startStr: string; endStr: string } | null;
}) => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent, isAdmin } = useAppSelector(
    (state) => state.adminParent,
  );
  const enabled = isAdmin ? !!token && !!activeParent?.email : !!token;

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [`events-by-parentId`, activeParent?.email, dates],
    enabled,
    queryFn: async () => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}event/geteventsbyrange`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
          params: {
            from: dates?.startStr,
            to: dates?.endStr,
          },
        },
      );
      return res.data;
    },
  });

  return {
    data: data?.map((item: EventItemType) => ({
      title: item?.title,
      description: item?.body,
      start: item?.startAt,
      end: item?.endAt,
      allDay: item?.allDay === 1 ? true : false,
      extendedProps: {
        ...item,
      },
    })),
    error,
    isFetching,
  };
};
