import { useState } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { PATHS } from '../../../types';
import { stringAvatar } from '../../../utils/avatar';
import { useProfile } from '../../../modules/profile/hooks/useProfile';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../state/stores/root.store';
import { resetParentAdmin } from '../../../state/slices/admin-parent.slice';
import { resetCurrentStudent } from '../../../state/slices/student.slice';

const settings = [
  {
    label: 'My Profile',
    path: PATHS.PROFILE,
  },
  {
    label: 'Sign out',
    path: PATHS.LOGOUT,
  },
];

const ProfileMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isAdmin } = useAppSelector((state) => state.adminParent);

  const { profile, isLoading, isPending, isError } = useProfile();
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { instance } = useMsal();
  const acronym = `${profile?.forename?.[0]}${profile?.surname?.[0]}`;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = async () => {
    const accounts = instance.getAllAccounts();
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts?.[0]?.homeAccountId),
      mainWindowRedirectUri: '/',
      postLogoutRedirectUri: '/',
    };
    await instance.logoutPopup(logoutRequest).then(() => {
      dispatch(resetParentAdmin());
      dispatch(resetCurrentStudent());
    });

    return;
  };

  /**
   * TODO: Handle Error
   */

  // useEffect(() => {
  //   if (parent?.status === 'rejected') {
  //     void handleSignout();
  //   }
  // }, [isError]);

  return (
    <Box
      sx={{ flexGrow: 0, position: 'relative' }}
      height='100%'
      data-id='profile-menu'
    >
      <Stack
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-end'
        data-id='profile-menu-trigger'
        height='100%'
      >
        {isAdmin ? (
          <IconButton
            onClick={handleClick}
            sx={{ p: 0, minWidth: 30, minHeight: 30 }}
          >
            <Avatar
              {...stringAvatar('A' ?? '')}
              sx={{
                bgcolor: '#1F3263',
                color: '#FFFFFF',
              }}
            />
          </IconButton>
        ) : (
          <>
            {isLoading || isPending || isError ? (
              <Stack
                position='relative'
                display='flex'
                alignItems='center'
                justifyContent='center'
                width={50}
                height={50}
              >
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    minWidth: '50px',
                    minHeight: '50px',
                    overflow: 'visible',
                  }}
                />
                <IconButton
                  onClick={handleClick}
                  sx={{ p: 0, minWidth: 30, minHeight: 30 }}
                >
                  {profile && (
                    <Avatar
                      {...stringAvatar('MLC')}
                      sx={{
                        bgcolor: '#1F3263',
                        color: '#FFFFFF',
                        fontSize: 12,
                      }}
                    />
                  )}
                </IconButton>
              </Stack>
            ) : (
              <IconButton
                onClick={handleClick}
                sx={{ p: 0, minWidth: 30, minHeight: 30 }}
              >
                {profile && (
                  <Avatar
                    {...stringAvatar(acronym ?? '')}
                    sx={{
                      bgcolor: '#1F3263',
                      color: '#FFFFFF',
                    }}
                  />
                )}
              </IconButton>
            )}
          </>
        )}
      </Stack>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <Typography
          variant='body1'
          sx={{ fontSize: '14px', fontWeight: 'bold', px: '1rem' }}
        >
          {profile?.contactName || 'Admin'}
        </Typography>
        <hr />
        {settings.map(({ label, path }) => (
          <MenuItem
            key={label}
            onClick={() => {
              handleClose();

              if (path === PATHS.LOGOUT) {
                return void handleSignout();
              }

              navigate(path);
            }}
          >
            <Typography textAlign='center'>{label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export { ProfileMenu };
