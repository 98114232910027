import { WidgetInput } from 'react-addressfinder';

type AddressType = {
  country: string;
  line1: string;
  line2: string;
  postcode: string;
  state: string;
  suburb: string;
};

interface AddressFinderProps {
  id: string;
  defaultValue?: string;
  onSelect: (value: AddressType | null, stringAddress: string) => void;
}

const AddressFinder = ({
  id,
  defaultValue,
  onSelect,
  ...props
}: AddressFinderProps) => {
  const addressFinderKey = process.env.REACT_APP_ADDRESS_FINDER_KEY ?? '';
  return (
    <WidgetInput
      addressFinderKey={addressFinderKey}
      id={id}
      defaultValue={defaultValue}
      onSelected={(stringAddress, fullAddress: unknown) => {
        onSelect?.(fullAddress as AddressType, stringAddress);
      }}
      onChange={() => {
        onSelect?.(null, '');
      }}
      inputClassName={`address-finder-input`}
      {...props}
    />
  );
};

export { AddressFinder };
