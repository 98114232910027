import { useMediaQuery as useMediaQueryHooks } from '@uidotdev/usehooks';

export const useMediaQuery = () => {
  const isSmallDevice = useMediaQueryHooks(
    'only screen and (max-width : 768px)',
  );
  const isMediumDevice = useMediaQueryHooks(
    'only screen and (min-width : 769px) and (max-width : 992px)',
  );
  const isLargeDevice = useMediaQueryHooks(
    'only screen and (min-width : 993px) and (max-width : 1200px)',
  );
  const isExtraLargeDevice = useMediaQueryHooks(
    'only screen and (min-width : 1201px)',
  );

  return {
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isExtraLargeDevice,
  };
};
