import { Link, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

const AttachmentContact = ({
  contactName,
  contactPhone,
  contactEmail,
}: Props) => {
  return (
    <Stack border='1px solid #cccccc' borderRadius={2} p={2}>
      <Typography>{contactName}</Typography>
      {contactPhone && (
        <Link href={`tel:${contactPhone}`}>
          <Typography>{contactPhone}</Typography>
        </Link>
      )}
      {contactEmail && (
        <Link href={`mailto:${contactEmail}`} target='_blank'>
          <Typography>{contactEmail}</Typography>
        </Link>
      )}
    </Stack>
  );
};

export { AttachmentContact };
