import { displayDayOfWeek } from '../../utils/displayDayOfWeek';
import { displayMonthDay } from '../../utils/displayMonthDay';
import { displayTime12HourFormat } from '../../utils/displayTime';

export const displayTime = (date: string) => {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
};

export const formatActivityDate = (dateString: string) => {
  return `${displayDayOfWeek(dateString)} ${displayMonthDay(dateString)}`;
};

export const formatActivityTime = (startAt: string, endAt: string) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true, // Enable 12-hour format with am/pm
  };

  const startTime = new Date(startAt).toLocaleTimeString([], options);
  const endTime = new Date(endAt).toLocaleTimeString([], options);

  return `${startTime} - ${endTime}`;
};

export const IsMultiDayExcursion = (
  fromDateString: string,
  toDateString: string,
) => {
  return fromDateString !== toDateString;
};

export const formatMultiDayActivityDate = (
  fromDateString: string,
  toDateString: string,
  startTime: string,
  endTime: string,
) => {
  const fromDate = new Date(fromDateString);
  const toDate = new Date(toDateString);

  const fromDateStringFormatted =
    displayTime12HourFormat(startTime) +
    ' ' +
    fromDate.toLocaleDateString('en-US', { weekday: 'short' }) +
    ' ' +
    fromDate.getDate().toString().padStart(2, '0') +
    ' ' +
    fromDate.toLocaleDateString('en-US', { month: 'long' });
  const toDateStringFormatted =
    displayTime12HourFormat(endTime) +
    ' ' +
    toDate.toLocaleDateString('en-US', { weekday: 'short' }) +
    ' ' +
    toDate.getDate().toString().padStart(2, '0') +
    ' ' +
    toDate.toLocaleDateString('en-US', { month: 'long' });

  return `${fromDateStringFormatted} - <br />${toDateStringFormatted}`;
};
