import { Box, Button, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { EventClickArg } from '@fullcalendar/core';
import { withHttp } from '../../../utils/linkCheck';

interface Props {
  activeEvent: EventClickArg;
}

const ContentCms = ({ activeEvent }: Props) => {
  const content = activeEvent?.event?.extendedProps?.cmsEvent;

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Box>
          <Typography fontSize={16} fontWeight={600}>
            {content?.name}
          </Typography>
          <Typography fontSize={11}>
            {content.startAt &&
              format(new Date(String(content?.startAt)), 'EEEE dd MMMM yyyy')}
            {` -`}
            {content.endAt &&
              format(new Date(String(content?.endAt)), 'EEEE dd MMMM yyyy')}
          </Typography>
        </Box>
        {content?.isFree && (
          <Stack width='100%' alignItems='flex-end'>
            <Box sx={{ border: '1px solid', borderRadius: '10%', px: 1 }}>
              <Typography fontSize={13}>FREE</Typography>
            </Box>
          </Stack>
        )}
        <Stack width='100%' alignItems='center'>
          <img
            src={content?.imageUrl}
            alt={content?.imageUrl}
            style={{ maxWidth: '100%' }}
          />
        </Stack>
        <Box>
          <Typography
            variant='body1'
            color='black'
            dangerouslySetInnerHTML={{ __html: content?.description }}
          />
        </Box>

        <Stack flexDirection={{ xs: 'column', sm: 'row' }} gap={2} pt={3}>
          {content.eventLinkUrl && content.eventLinkTitle && (
            <Button
              component='a'
              target='_blank'
              href={withHttp(content.eventLinkUrl as string)}
              variant='contained'
            >
              {content.eventLinkTitle}
            </Button>
          )}
          {content.rsvpFormLinkUrl && content.rsvpFormLinkTitle && (
            <Button
              component='a'
              target='_blank'
              href={withHttp(content.rsvpFormLinkUrl as string)}
              variant='outlined'
            >
              {content.rsvpFormLinkTitle}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export { ContentCms };
