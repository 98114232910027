import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAppConfig } from '../appConfig';

const appConfig = getAppConfig();

export const api = createApi({
  reducerPath: 'parent',
  baseQuery: fetchBaseQuery({ baseUrl: appConfig.app.baseUrl }),
  endpoints: (build) => ({
    getParentTodos: build.query({
      query: ({ token, page }) => ({
        url: 'parent/gettodolist',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          itemsperpage: 10,
        },
      }),
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        currentCache.results.push(...newItems.results);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getParentCompletedTodos: build.query({
      query: ({ token, page }) => ({
        url: 'parent/getreadonlytodolist',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          itemsperpage: 10,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.results.push(...newItems.results);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    updateParentProfile: build.mutation({
      query: ({ token, payload }) => ({
        url: 'parent/updateprofile',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: payload,
      }),
    }),
    updateAddress: build.mutation({
      query: ({ token, payload }) => ({
        url: 'parent/requestaddresschange',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: payload,
      }),
    }),
    addExcursionPermission: build.mutation({
      query: ({ token, payload }) => ({
        url: `parent/addexcursionpermission?pupilId=${payload?.pupilId}&activityId=${payload?.activityId}&isGranted=${payload?.isGranted}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});
export const {
  useUpdateParentProfileMutation,
  useUpdateAddressMutation,
  useGetParentTodosQuery,
  useGetParentCompletedTodosQuery,
  useAddExcursionPermissionMutation,
} = api;
