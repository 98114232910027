import { Dialog as MuiDIalog } from '@mui/material';
import { Box, Button } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Fragment, useState } from 'react';

interface Props {
  imageUrl: string;
  videoId: string;
  isOverlay: boolean;
  isFetching?: boolean;
}

export const ContentVideo = ({
  isFetching = false,
  imageUrl,
  videoId,
  isOverlay,
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const videoPlayerUrl = `https://player.vimeo.com/video/${videoId}`;

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  if (isFetching) {
    return <Box>Loading....</Box>;
  }

  return (
    <Fragment>
      <Box my={3}>
        <Box width='100%' position='relative'>
          {isOverlay && imageUrl ? (
            <Box position='relative'>
              <img src={imageUrl} alt={imageUrl} width='100%' />
              <Button
                variant='text'
                onClick={toggleModal}
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <PlayCircleOutlineIcon
                  sx={{
                    fontSize: { xs: '10vw', md: '3vw' },
                    color: '#FFFFFF',
                  }}
                />
              </Button>
            </Box>
          ) : null}

          {!isOverlay && videoId ? (
            <Box
              width='100%'
              maxHeight={{ xs: '500px', md: '1000px' }}
              height={{ xs: 'auto', md: '500px' }}
              position='relative'
            >
              <Box
                component='iframe'
                width='100%'
                height='100%'
                src={videoPlayerUrl}
                allow='autoplay; fullscreen; picture-in-picture'
                allowFullScreen
              ></Box>
            </Box>
          ) : null}
        </Box>
      </Box>

      {isOverlay ? (
        <MuiDIalog
          open={isModalOpen}
          onClose={toggleModal}
          PaperProps={{
            style: {
              maxWidth: '100vw',
              maxHeight: '100vh',
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <Box
            component='iframe'
            width={{ xs: '100%', sm: '520px', md: '760px' }}
            height='500px'
            src={videoPlayerUrl}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </MuiDIalog>
      ) : null}
    </Fragment>
  );
};
