import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../types';
import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';

export const RootPage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return navigate(PATHS.DASHBOARD);
    }
  }, [isAuthenticated]);

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      height='100dvh'
      width='100dvw'
      gap={4}
      sx={{ bgcolor: (theme) => theme.palette.primary.main }}
    >
      <Box>
        <img src='/logo.png' alt='MLC' />
      </Box>
      <CircularProgress size={40} sx={{ color: '#FFFFFF' }} />
    </Stack>
  );
};
