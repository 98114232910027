import { Box, Typography } from '@mui/material';

interface Props {
  title: string;
}

export const SubscribedTagsItem = ({ title }: Props) => {
  return (
    <Box px={1} py={0.5} bgcolor='#F4EFEF' borderRadius='4px'>
      <Typography variant='body2' fontWeight={300}>
        {title}
      </Typography>
    </Box>
  );
};
