import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EventUmbracoDataType,
  EventUmbracoItemType,
} from '../../types/events.type';

interface EventState {
  eventPage: number;
  eventsData: Array<EventUmbracoDataType>;
  todo: Array<EventUmbracoItemType>;
  hasNextPage: boolean;
}

const initialState: EventState = {
  eventPage: 1,
  eventsData: [],
  todo: [],
  hasNextPage: false,
};

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEventPage(state: EventState) {
      state.eventPage = state.eventPage + 1;
    },
    setEventsData(
      state: EventState,
      action: PayloadAction<EventUmbracoDataType>,
    ) {
      state.eventsData = [...state.eventsData, action?.payload];
    },
    setTodoData(
      state: EventState,
      action: PayloadAction<EventUmbracoItemType[]>,
    ) {
      state.todo = action?.payload;
    },
    setHasNextPage(state: EventState, action: PayloadAction<boolean>) {
      state.hasNextPage = action?.payload;
    },
  },
});

export const { reducer } = slice;
export const { setHasNextPage, setEventPage, setEventsData, setTodoData } =
  slice.actions;
