import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  name: string;
  imageUrl: string;
  isFetching?: boolean;
}

export const ContentThumbnailImage = ({
  isFetching = false,
  imageUrl,
  name,
  ...props
}: Props) => {
  if (isFetching) {
    return <Box>Loading....</Box>;
  }

  return (
    <Box {...props}>
      {imageUrl ? (
        <a href={imageUrl} target='_blank' rel='noopener noreferrer'>
          <img
            src={imageUrl}
            alt={name}
            style={{ maxWidth: '100px', maxHeight: '100px' }}
          />
        </a>
      ) : null}
    </Box>
  );
};
