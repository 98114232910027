export const displayDayOfWeek = (date: string) => {
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  if (!date) {
    return '';
  }

  const dateItem = new Date(date);
  const displayDay = weekday[dateItem.getDay()];

  return displayDay;
};
