import { Fragment } from 'react';
import { Avatar } from '@mui/material';
import { stringAvatar } from '../../utils/avatar';
import { useGetPhoto } from './useGetPhoto';

interface Props {
  size?: number;
  fullName: string;
  acronym: string;
  pupilId: string;
}

const StudentAvatar = ({ size = 30, fullName, acronym, pupilId }: Props) => {
  const { photo } = useGetPhoto(pupilId);

  return (
    <Fragment>
      {photo ? (
        <Avatar alt={fullName} src={photo} sx={{ width: size, height: size }} />
      ) : (
        <Avatar {...stringAvatar(acronym ?? '', size)} />
      )}
    </Fragment>
  );
};

export { StudentAvatar };
