import { displayMonthDay } from '../../../../../utils/displayMonthDay';
import { WrapperView } from './WrapperView';

export const StudentDateOfBirth = ({ birthday }: { birthday: string }) => {
  return (
    <WrapperView
      label='Date of Birth'
      value={birthday === null ? 'N/A' : displayMonthDay(birthday, true)}
    />
  );
};
