import axios from 'axios';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../../../hooks/useMsalIdToken';
import { getAppConfig } from '../../../../appConfig';
import { useAppSelector } from '../../../../state/stores/root.store';

export const useExcursionTodo = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent } = useAppSelector((state) => state.adminParent);

  const { data, error, isFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      'todos-excursions',
      activeParent?.email && `todos-excursions-${activeParent?.email}`,
    ],
    enabled: !!token,
    queryFn: async () => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}parent/getexcursionstodolist`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
        },
      );
      return res.data;
    },
  });

  return {
    todos: data,
    error,
    isFetching,
  };
};

export const useExcursionTodoCompleted = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent } = useAppSelector((state) => state.adminParent);

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    queryKey: ['todos-excursions-completed'],
    enabled: !!token,
    queryFn: async ({ pageParam }) => {
      const res = await axios.get(
        `${appConfig.app
          .baseUrl!}parent/getreadonlyexcursionstodolist?page=${pageParam}&itemsperpage=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
        },
      );
      return res.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage?.hasNextPage) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });

  return {
    todos: data,
    error,
    isFetching,
    isFetchingNextPage,
    isEmpty: data?.pages?.[0]?.totalCount === 0,
    hasNextPage,
    fetchNextPage,
  };
};
