import {
  Autocomplete,
  Box,
  TextField,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from '../../../state/stores/root.store';
import {
  ParentType,
  refreshAdmin,
  resetParentAdmin,
  setAdminParent,
} from '../../../state/slices/admin-parent.slice';
import { useDispatch } from 'react-redux';
import { useStaffAdmin } from './useStaffAdmin';
import { useDebounce } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router-dom';
import { resetCurrentStudent } from '../../../state/slices/student.slice';
import { PATHS } from '../../../types';

const LANG = {
  TITLE: 'Administrator Options',
  SUBTITLE:
    'You are currently logged in with an Admin account. To impersonate a parent user, search for the parent name or parent email address',
};

const ParentSelect = () => {
  const { isAdmin, activeParent } = useAppSelector(
    (state) => state.adminParent,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedParent, setSelectedParent] = useState<ParentType | null>(null);
  const [query, setQuery] = useState('');

  const debouncedSearchTerm = useDebounce(query, 300);

  const { data, isFetching } = useStaffAdmin({ query: debouncedSearchTerm });

  if (!isAdmin) {
    return null;
  }

  return (
    <Box p={3} pb={0}>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems='center'
        bgcolor='#1F3263'
        p={2}
        gap={2}
        borderRadius={2}
      >
        <Stack>
          <Typography variant='body1' fontWeight={600} color='white'>
            {LANG.TITLE}
          </Typography>
          <Typography variant='caption' color='white'>
            {LANG.SUBTITLE}
          </Typography>
        </Stack>
        <Stack>
          <Autocomplete
            disablePortal
            id='parent-list'
            options={data ?? []}
            size='small'
            sx={{ width: 300 }}
            loading={isFetching}
            getOptionLabel={(option: any) =>
              `${option.fullname} - ${option.email}`
            }
            onChange={(_, newValue) => {
              setSelectedParent(newValue as ParentType);
            }}
            value={selectedParent ?? activeParent}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Parents'
                sx={{ bgcolor: 'white', borderRadius: 2 }}
                onChange={(event) => {
                  setQuery(event?.target?.value);
                }}
              />
            )}
          />
        </Stack>
        <Stack>
          <Button
            sx={{ bgcolor: '#858080' }}
            onClick={() => {
              dispatch(refreshAdmin());
              dispatch(resetCurrentStudent());
              dispatch(setAdminParent(selectedParent!));
              navigate(PATHS.DASHBOARD);
            }}
          >
            <Typography variant='caption' color='white' fontWeight={600}>
              Impersonate
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ParentSelect;
