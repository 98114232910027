import {
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Link,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useEffect } from 'react';
import { navigationMenu } from '../../../config/navigation.config';

interface SidebarProps {
  width?: string | number;
  open: boolean;
  variant: 'permanent' | 'persistent' | 'temporary' | undefined;
  onClose: () => void;
}

const Sidebar = ({ width, open, variant, onClose }: SidebarProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <Drawer
      open={open}
      variant={variant}
      onClose={onClose}
      sx={{
        flexShrink: { sm: 0 },
        width,
      }}
      PaperProps={{
        sx: {
          bgcolor: '#1F3263',
          width,
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Box my={4} px={2}>
        <img src='/logo.png' height='44px' width='125px' alt='logo' />
      </Box>
      <List sx={{ flex: 1 }}>
        {navigationMenu.map(({ label, path, Icon, external }) => {
          const isCurrentPath = pathname.includes(path);

          if (external) {
            return (
              <Link
                href={path}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  px: 2,
                  py: 1,
                  color: '#D9D9D9',
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
                target='_blank'
              >
                <Icon
                  sx={{
                    color: '#906d4f',
                  }}
                />
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{
                    fontSize: '14px',
                    fontWeight: 'normal',
                    width: 'auto',
                  }}
                  sx={{ display: 'inline', width: 'auto', flex: 'none' }}
                />
                <OpenInNewOutlinedIcon
                  sx={{
                    color: '#D9D9D9',
                    height: '20px',
                  }}
                />
              </Link>
            );
          }

          return (
            <ListItemButton
              key={`desktop-navitem-${label}`}
              onClick={() => navigate(path)}
              sx={{
                display: 'flex',
                gap: 2,
                bgcolor: isCurrentPath ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
              }}
            >
              <Icon
                sx={{
                  color: '#906d4f',
                }}
              />
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  color: isCurrentPath ? '#FFFFFF' : '#D9D9D9',
                  fontSize: '14px',
                  fontWeight: isCurrentPath ? 'bold' : 'normal',
                  width: '150px',
                }}
                sx={{
                  '&:hover > span': {
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                  },
                }}
              />
            </ListItemButton>
          );
        })}
      </List>
    </Drawer>
  );
};

export { Sidebar };
