import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  content: string;
  isFetching?: boolean;
}

export const ContentIframe = ({
  isFetching = false,
  content,
  ...props
}: Props) => {
  if (isFetching) {
    return <Box>Loading....</Box>;
  }

  return (
    <Box
      component='div'
      dangerouslySetInnerHTML={{ __html: content }}
      {...props}
    />
  );
};
