import { Button, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode, useEffect, useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { SubscribedTagsItemButton } from './subscribed-tags-item-button';
import { CategoryItemType } from '../../../types/category.type';

interface Props {
  footer: ReactNode;
  categories: Array<CategoryItemType>;
  selectedTags: Array<string>;
  onClose: () => void;
  handleSelectedTags: (value: string) => void;
}

export const SubscribedTagsDrawerContent = ({
  footer,
  categories,
  selectedTags,
  onClose,
  handleSelectedTags,
}: Props) => {
  const [activeCategory, setActiveCategory] = useState('');

  const BadgeCount = ({ value }: { value: number }) => {
    if (!value) {
      return null;
    }

    return (
      <Stack
        justifyContent='center'
        alignItems='center'
        width={24}
        height={24}
        borderRadius={24}
        bgcolor='#876148'
      >
        <Typography variant='body1' fontWeight={700} color='white'>
          {value}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Stack justifyContent='space-between' height='100%'>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          borderBottom='1px solid rgba(0, 0, 0, 0.2)'
          px={3}
          py={1}
          gap={2}
          height='36px'
        >
          <Typography variant='subtitle1' fontWeight={700}>
            Subscribe to Tags
          </Typography>
          <IconButton
            sx={{
              bgcolor: '#1F3263',
              width: '20px',
              height: '20px',
              '&:hover': {
                bgcolor: '#1F3263',
              },
            }}
            onClick={() => onClose()}
          >
            <CloseIcon sx={{ color: 'white', fontSize: 16 }} />
          </IconButton>
        </Stack>

        <Stack
          flex={1}
          height='calc(100vh - 72px)'
          bgcolor='white'
          overflow='auto'
          px={3}
        >
          {categories?.map((category) => {
            return (
              <Stack
                borderBottom='1px solid rgba(0, 0, 0, 0.2)'
                py={1}
                key={category.id}
              >
                <Button
                  onClick={() =>
                    setActiveCategory(
                      activeCategory === category?.name ? '' : category?.name,
                    )
                  }
                  disabled={category?.tags?.length === 0}
                >
                  <Stack
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                  >
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      textTransform='none'
                    >
                      {category?.name}
                    </Typography>
                    <Stack flexDirection='row' alignItems='center' gap={1}>
                      <BadgeCount
                        value={
                          selectedTags.filter((item) =>
                            item.startsWith(String(category?.id)),
                          ).length
                        }
                      />
                      <KeyboardArrowDown />
                    </Stack>
                  </Stack>
                </Button>
                {activeCategory === category?.name && (
                  <Stack py={1}>
                    <Stack flexDirection='row' flexWrap='wrap' gap={2}>
                      {category?.tags?.map((tag) => {
                        return (
                          <SubscribedTagsItemButton
                            key={tag?.id}
                            title={tag?.name}
                            onClick={() =>
                              handleSelectedTags(`${category.id}-${tag?.id}`)
                            }
                            isActive={selectedTags?.includes(
                              `${category.id}-${tag?.id}`,
                            )}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            );
          })}

          {/* <Stack borderBottom='1px solid rgba(0, 0, 0, 0.2)' py={1}>
            <Button onClick={() => setActiveCategory('Co-Curricular - Dance')}>
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'
              >
                <Typography
                  variant='body1'
                  fontWeight={500}
                  textTransform='none'
                >
                  Co-Curricular - Dance
                </Typography>
                <Stack flexDirection='row' alignItems='center' gap={1}>
                  <BadgeCount value={0} />
                  <KeyboardArrowDown />
                </Stack>
              </Stack>
            </Button>
            {activeCategory === 'Co-Curricular - Dance' && (
              <h1>Co-Curricular - Dance</h1>
            )}
          </Stack>

          <Stack borderBottom='1px solid rgba(0, 0, 0, 0.2)' py={1}>
            <Button
              onClick={() =>
                setActiveCategory('Co-Curricular - Mind Challenges')
              }
            >
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'
              >
                <Typography
                  variant='body1'
                  fontWeight={500}
                  textTransform='none'
                >
                  Co-Curricular - Mind Challenges
                </Typography>
                <Stack flexDirection='row' alignItems='center' gap={1}>
                  <BadgeCount value={0} />
                  <KeyboardArrowDown />
                </Stack>
              </Stack>
            </Button>
            {activeCategory === 'Co-Curricular - Mind Challenges' && (
              <h1>Co-Curricular - Mind Challenges</h1>
            )}
          </Stack>

          <Stack borderBottom='1px solid rgba(0, 0, 0, 0.2)' py={1}>
            <Button onClick={() => setActiveCategory('Co-Curricular - Music')}>
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'
              >
                <Typography
                  variant='body1'
                  fontWeight={500}
                  textTransform='none'
                >
                  Co-Curricular - Music
                </Typography>
                <Stack flexDirection='row' alignItems='center' gap={1}>
                  <BadgeCount value={0} />
                  <KeyboardArrowDown />
                </Stack>
              </Stack>
            </Button>
            {activeCategory === 'Co-Curricular - Music' && (
              <h1>Co-Curricular - Music</h1>
            )}
          </Stack> */}
        </Stack>

        {footer}
      </Stack>
    </>
  );
};
