import ReactGA from 'react-ga4';

export const useTrack = () => {
  const trackPage = ({ title, page }: { title: string; page: string }) => {
    return ReactGA.send({
      hitType: 'pageview',
      page,
      title,
    });
  };

  return {
    trackPage,
  };
};
