import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NoticeDataType, NoticeItemType } from '../../types/notices.type';
import { TodoItemType } from '../../types/todo.types';

interface TodosState {
  todosPage: number;
  todosData: Array<TodoItemType>;
  hasNextPage: boolean;
}

const initialState: TodosState = {
  todosPage: 1,
  todosData: [],
  hasNextPage: false,
};

const slice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    setTodoPage(state: TodosState) {
      state.todosPage = state.todosPage + 1;
    },
    setTodosData(
      state: TodosState,
      action: PayloadAction<Array<TodoItemType>>,
    ) {
      state.todosData = state?.todosData.concat(action?.payload);
    },
    setHasNextPage(state: TodosState, action: PayloadAction<boolean>) {
      state.hasNextPage = action?.payload;
    },
  },
});

export const { reducer } = slice;
export const { setHasNextPage, setTodoPage, setTodosData } = slice.actions;
