import { Box, Button, Typography } from '@mui/material';

interface Props {
  title: string;
  isActive: boolean;
  onClick: () => void;
}

export const SubscribedTagsItemButton = ({
  title,
  isActive,
  onClick,
}: Props) => {
  return (
    <Button
      sx={{
        px: 1,
        py: 0.5,
        bgcolor: isActive ? '#876148' : '#F4EFEF',
        borderRadius: '4px',
        '&:hover': {
          bgcolor: isActive ? '#876148' : '#F4EFEF',
        },
      }}
      onClick={onClick}
    >
      <Typography
        variant='body2'
        fontSize={12}
        fontWeight={isActive ? 600 : 300}
        color={isActive ? 'white' : 'black'}
        textTransform='none'
      >
        {title}
      </Typography>
    </Button>
  );
};
