import { Box, Stack } from '@mui/material';

import { SchoolPageBlockType } from '../../../types/schoolinformation.type';
import { ContentImage } from '../../../components/content-blocks/content-image';
import { ContentIframe } from '../../../components/content-blocks/content-iframe';
import { ContentVideo } from '../../../components/content-blocks/content-video';
import { RteContentBlock } from '../../../components/content-blocks/rte-content-block';

interface Props {
  content?: SchoolPageBlockType;
}

export const ContentPage = ({ content }: Props) => {
  return (
    <Stack px={2}>
      <Box>
        {content?.blocks?.map((block: string, index: number) => {
          const parseBlock = JSON.parse(block);

          if (parseBlock?.ContentType === 'rTEContentBlock') {
            return (
              <RteContentBlock
                key={`rte-content-block-${index}`}
                content={parseBlock?.Text}
                sx={{
                  '& p': {
                    lineHeight: '1.5',
                  },
                }}
              />
            );
          }

          if (parseBlock?.ContentType === 'embedIframeBlock') {
            return (
              <ContentIframe
                key={`content-iframe-${index}`}
                content={parseBlock?.Iframe}
                sx={{
                  width: '100%',
                  marginBottom: '100px',
                }}
              />
            );
          }

          if (parseBlock.ContentType === 'videoEmbedBlock') {
            return (
              <ContentVideo
                imageUrl={parseBlock?.Thumbnail}
                videoId={parseBlock?.VideoId}
                isOverlay={parseBlock?.Overlay}
              />
            );
          }

          if (parseBlock.ContentType === 'imageBlock') {
            return (
              <ContentImage
                name={parseBlock?.Name}
                imageUrl={parseBlock?.Url}
                sx={{
                  width: '100%',
                  marginBottom: '100px',
                }}
              />
            );
          }

          return <Box key={`content-block-${index}`} />;
        })}
      </Box>
    </Stack>
  );
};
