import {
  Stack,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
} from '@mui/material';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useGetSortedPupils } from '../../../hooks/useGetSortedPupils';
import { StudentAvatar } from '../../../components/student-list/student-avatar';

const EmptyComingUp = () => {
  const { isSmallDevice } = useMediaQuery();
  const { pupils } = useGetSortedPupils();

  return (
    <Stack py={2} px={2}>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align='left'
                  sx={{ px: 0, py: 1, borderBottom: 0 }}
                ></TableCell>
                {!isSmallDevice ? (
                  <TableCell
                    align='left'
                    sx={{ px: 0, py: 1, borderBottom: 0 }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 14,
                        color: '#916d4f',
                      }}
                    >
                      Next Co-curricular Activity
                    </Typography>
                  </TableCell>
                ) : null}
                <TableCell align='left' sx={{ px: 0, py: 1, borderBottom: 0 }}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      color: '#916d4f',
                    }}
                  >
                    Next Approved Excursion
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pupils?.map((pupil) => (
                <TableRow key={pupil?.forename}>
                  <TableCell sx={{ px: 0, py: 1 }}>
                    <Stack flexDirection='row' alignItems='center' gap={1}>
                      <StudentAvatar
                        pupilId={pupil?.pupilId}
                        fullName={`${pupil?.forename} ${pupil?.surname}` ?? ''}
                        acronym={pupil?.avatarAcronym ?? ''}
                        size={36}
                      />
                      <Stack>
                        <Typography
                          sx={{
                            fontWeight: 'regular',
                            fontSize: 18,
                            color: '#1f3263',
                          }}
                        >
                          {pupil.forename}
                        </Typography>
                        {isSmallDevice ? (
                          <Typography
                            sx={{
                              fontWeight: 'regular',
                              fontSize: 18,
                              color: '#1f3263',
                            }}
                          >
                            {pupil.forename}
                          </Typography>
                        ) : null}
                      </Stack>
                    </Stack>
                  </TableCell>
                  {!isSmallDevice ? (
                    <TableCell sx={{ px: 0, py: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: 'regular',
                          fontSize: 13,
                        }}
                      >
                        No co-curricular activities this term
                      </Typography>
                    </TableCell>
                  ) : null}
                  <TableCell align='left' sx={{ px: 0, py: 1 }}>
                    <Typography
                      sx={{
                        fontWeight: 'regular',
                        fontSize: 13,
                      }}
                    >
                      No approved excursions scheduled
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

export { EmptyComingUp };
