import {
  Box,
  Button,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { NoticeItem } from './notice-item';
import { NoticeInfo } from './notice-info';
import { useEffect, useState } from 'react';
import { NoticeItemType } from '../../../types/notices.type';
import { ContentCard } from '../../../components/layout/ContentCard';
import { useNotices } from './useNotices';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { Loader } from '../../../components/loader';
import { ToastView } from '../../../components/toast';
import { toast } from 'react-toastify';

export const Notices = () => {
  const {
    notices,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    error,
  } = useNotices();
  const { isSmallDevice } = useMediaQuery();
  const [currentNotice, setNotice] = useState<NoticeItemType | null>(null);
  const [isInfoOpen, setInfoOpen] = useState(false);

  const handleSelectNotice = (notice: NoticeItemType) => {
    setInfoOpen(true);
    setNotice(notice);
  };

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load News.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  const Content = () => {
    return (
      <>
        <Box maxHeight='calc(calc(100vh - 200px) / 2)' overflow='auto'>
          {notices?.pages?.map((noticeArray) => {
            return noticeArray?.items?.map(
              (noticeItem: NoticeItemType, noticeIndex: number) => {
                return (
                  <Stack key={`notice-${noticeIndex}`} px={2}>
                    <NoticeItem
                      notice={noticeItem}
                      handleSelect={() => handleSelectNotice(noticeItem)}
                    />
                    {isSmallDevice ? <Divider /> : null}
                  </Stack>
                );
              },
            );
          })}
          {hasNextPage && (
            <Button
              variant='text'
              sx={{ display: 'flex', gap: 1, marginLeft: '106px' }}
              onClick={() => void fetchNextPage()}
            >
              <Typography
                color='#1F3263'
                fontWeight={600}
                fontSize={12}
                textTransform='initial'
                sx={{ textDecoration: 'underline' }}
              >
                View more
              </Typography>
            </Button>
          )}
          {isFetchingNextPage && (
            <Stack gap={1} px={2} py={2}>
              {[...Array(4)]?.map(() => (
                <Skeleton
                  variant='rectangular'
                  width='100%'
                  height={36}
                  sx={{
                    borderRadius: '5px',
                    display: 'flex',
                  }}
                />
              ))}
            </Stack>
          )}
        </Box>
        <NoticeInfo
          open={isInfoOpen}
          handleClose={() => setInfoOpen(false)}
          notice={currentNotice}
        />
      </>
    );
  };

  return (
    <ContentCard
      icon={<ArticleOutlinedIcon />}
      title='News'
      titleAttributes={{ 'data-tour': 'news' }}
      content={
        <>
          {!isFetchingNextPage && isFetching ? (
            <Loader count={10} />
          ) : (
            <Content />
          )}
        </>
      }
    />
  );
};
