import { Stack, Typography } from '@mui/material';
import { WrapperView } from './WrapperView';

interface Props {
  address: string;
  studentId: string;
  fullName: string;
}

export const StudentName = ({ fullName, studentId, address }: Props) => {
  return (
    <>
      <WrapperView label='Name' value={fullName} />
      <WrapperView label='Student Id' value={studentId} />
      <WrapperView label='Address' value={address} />
    </>
  );
};
