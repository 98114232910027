const routeName: { [key: string]: string } = {
  dashboard: 'Dashboard',
  'my-students': 'My Students',
  timetable: 'Timetable',
  contacts: 'School Contacts',
  calendar: 'School Calendar',
  'school-information': 'School Information',
  profile: 'Profile',
  'co-curricular': 'Co-Curricular',
};

export { routeName };
