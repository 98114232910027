import { PupilType } from '../types/students.type';
import { useStudents } from './useStudents';

const useGetPupils = () => {
  const { data: pupils, isFetching } = useStudents();

  const getPupilInfo = (pupilId: string) => {
    const pupil = pupils?.filter(
      (pupilItem: PupilType) => pupilItem?.pupilId === pupilId,
    )?.[0];

    const fullName = `${pupil?.forename} ${pupil?.surname}`;

    return {
      fullName,
      ...pupil,
    };
  };

  return {
    isLoadingPupils: isFetching,
    isFetchingPupils: isFetching,
    pupils,
    getPupilInfo,
  };
};

export { useGetPupils };
