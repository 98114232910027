import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAppConfig } from '../appConfig';
const appConfig = getAppConfig();

export const api = createApi({
  reducerPath: 'medicalInformation',
  baseQuery: fetchBaseQuery({ baseUrl: appConfig.app.baseUrl! }),
  endpoints: (build) => ({
    getMedicalInformation: build.query({
      query: ({ token }) => ({
        url: 'Medical/GetMedicalInformation/information',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    updateMedicalInformation: build.mutation({
      query: ({ token, payload }) => ({
        url: 'medical/updatemedicalinformation',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetMedicalInformationQuery,
  useUpdateMedicalInformationMutation,
} = api;
