import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useMsalIdToken } from '../../../../hooks/useMsalIdToken';
import { AddressFinder } from '../../../../components/address-finder';
import { useEffect, useState } from 'react';
import { FormInput } from '../../../../components/form';
import { useUpdateAddressMutation } from '../../../../services/parent.service';
import { SuccessView } from './success';
import { ErrorView } from './error';
import { Dialog } from '../../../../components/dialog';

const feedback = `Please be aware, that for privacy reasons, this address request does not automatically change the address record for another parent for whom the School holds the same address.`;

type Address = {
  country: string;
  line1: string;
  line2: string;
  postcode: string;
  state: string;
  suburb: string;
};

interface EditAddressProps {
  currentResidentialAddress: {
    street: string;
    suburb: string;
    zipCode: string;
    country: string;
    state: string;
  };
  isOpen: boolean;
  handleClose: () => void;
}

const EditAddress = ({
  currentResidentialAddress,
  isOpen,
  handleClose,
}: EditAddressProps) => {
  const token = useMsalIdToken();

  const [residentialAddress, setResidentialAddress] = useState({
    street: '',
    suburb: '',
    zipCode: '',
    country: '',
    state: '',
  });

  const [selectedResidentail, setSelectedResidential] =
    useState<Address | null>(null);

  const [selectedFullAddress, setSelectedFullAddress] = useState('');

  const [
    updateAddress,
    {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
    },
  ] = useUpdateAddressMutation();

  const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const ResetView = ({
    label,
    onClick,
    disabled,
  }: {
    label: string;
    onClick: () => void;
    disabled: boolean;
  }) => {
    if (disabled) {
      return null;
    }

    return (
      <IconButton
        aria-label={label}
        onClick={onClick}
        onMouseDown={handleReset}
        edge='end'
      >
        <RestartAltIcon />
      </IconButton>
    );
  };

  const handleSubmit = () => {
    const ra = residentialAddress;
    const sr = selectedResidentail;
    const hasChange =
      ra.state !== sr?.state ||
      ra.suburb !== sr?.suburb ||
      ra.zipCode !== sr?.postcode ||
      ra.street !== sr?.line1 ||
      ra.country !== sr?.country;

    const payload = {
      residentialAddress,
      recommendedAddress: selectedFullAddress,
      hasChange,
    };

    void updateAddress({ token, payload });
  };

  useEffect(() => {
    setResidentialAddress({
      street: selectedResidentail?.line1 ?? '',
      suburb: selectedResidentail?.suburb ?? '',
      zipCode: selectedResidentail?.postcode ?? '',
      country: selectedResidentail?.country ?? '',
      state: selectedResidentail?.state ?? '',
    });
  }, [selectedResidentail]);

  useEffect(() => {
    setResidentialAddress({
      street: currentResidentialAddress?.street ?? '',
      suburb: currentResidentialAddress?.suburb ?? '',
      zipCode: currentResidentialAddress?.zipCode ?? '',
      country: currentResidentialAddress?.country ?? '',
      state: currentResidentialAddress?.state ?? '',
    });
  }, [currentResidentialAddress]);

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title={!isUpdateSuccess ? 'Edit Address' : ''}
      actions={
        isUpdateSuccess ? null : (
          <Stack
            flex={1}
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            py={1}
            px={3}
          >
            <Button onClick={handleClose} variant='outlined'>
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              onClick={() => handleSubmit()}
              disabled={isUpdating}
            >
              {isUpdating ? 'Saving...' : 'Save'}
            </Button>
          </Stack>
        )
      }
    >
      {isUpdateSuccess ? (
        <SuccessView />
      ) : (
        <Stack gap={2} py={3} minWidth={{ xs: 300, md: 500 }} maxWidth={600}>
          <Stack overflow='auto' height='100%'>
            <Stack pb={2} gap={2}>
              <Typography fontSize={14} fontWeight={500} pb={0}>
                Residential Address
              </Typography>
              <AddressFinder
                id='profile-edit-residential'
                onSelect={(value, fullAddress) => {
                  setSelectedResidential(value);
                  setSelectedFullAddress(fullAddress);
                }}
              />
              <>
                <FormInput
                  label='Street'
                  id='residential-street'
                  value={residentialAddress?.street}
                  onChange={(event) => {
                    setResidentialAddress((prevState) => ({
                      ...prevState,
                      street: event.target.value,
                    }));
                  }}
                  endAdornment={
                    <ResetView
                      label='reset-residential'
                      onClick={() =>
                        setResidentialAddress((prevState) => ({
                          ...prevState,
                          street: currentResidentialAddress?.street ?? '',
                        }))
                      }
                      disabled={!selectedResidentail}
                    />
                  }
                />

                <FormInput
                  label='Postcode'
                  value={residentialAddress?.zipCode}
                  onChange={(event) => {
                    setResidentialAddress((prevState) => ({
                      ...prevState,
                      zipCode: event.target.value,
                    }));
                  }}
                  endAdornment={
                    <ResetView
                      label='reset-zipcode'
                      onClick={() =>
                        setResidentialAddress((prevState) => ({
                          ...prevState,
                          zipCode: currentResidentialAddress?.zipCode ?? '',
                        }))
                      }
                      disabled={!selectedResidentail}
                    />
                  }
                />

                <FormInput
                  label='Suburb'
                  value={residentialAddress?.suburb}
                  onChange={(event) => {
                    setResidentialAddress((prevState) => ({
                      ...prevState,
                      suburb: event.target.value,
                    }));
                  }}
                  endAdornment={
                    <ResetView
                      label='reset-suburb'
                      onClick={() =>
                        setResidentialAddress((prevState) => ({
                          ...prevState,
                          suburb: currentResidentialAddress?.suburb ?? '',
                        }))
                      }
                      disabled={!selectedResidentail}
                    />
                  }
                />

                <FormInput
                  label='State'
                  value={residentialAddress?.state}
                  onChange={(event) => {
                    setResidentialAddress((prevState) => ({
                      ...prevState,
                      state: event.target.value,
                    }));
                  }}
                  endAdornment={
                    <ResetView
                      label='reset-suburb'
                      onClick={() =>
                        setResidentialAddress((prevState) => ({
                          ...prevState,
                          state: currentResidentialAddress?.state ?? '',
                        }))
                      }
                      disabled={!selectedResidentail}
                    />
                  }
                />

                <FormInput
                  label='Country'
                  value={residentialAddress?.country}
                  onChange={(event) => {
                    setResidentialAddress((prevState) => ({
                      ...prevState,
                      country: event.target.value,
                    }));
                  }}
                  endAdornment={
                    <ResetView
                      label='reset-country'
                      onClick={() =>
                        setResidentialAddress((prevState) => ({
                          ...prevState,
                          country: currentResidentialAddress?.country ?? '',
                        }))
                      }
                      disabled={!selectedResidentail}
                    />
                  }
                />
              </>

              <Stack>
                <Typography variant='caption'>{feedback}</Typography>
              </Stack>
            </Stack>

            <ErrorView isOpen={isUpdateError} />
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
};

export { EditAddress };
