import { Card, Stack, SxProps, Typography } from '@mui/material';
import { Fragment, ReactNode } from 'react';

interface ContentCardProps {
  title: ReactNode | string;
  titleAttributes?: object;
  headerCta?: ReactNode;
  content: ReactNode;
  sx?: SxProps;
  icon?: ReactNode;
}

const ContentCard = ({
  title,
  titleAttributes,
  headerCta,
  content,
  sx,
  icon,
}: ContentCardProps) => {
  return (
    <Card
      sx={{
        position: 'relative',
        boxShadow: 'none',
        borderRadius: '10px',
        height: '100%',
        ...sx,
      }}
    >
      <Stack
        flexDirection='row'
        borderBottom='1px solid #EBDFDF'
        alignItems='center'
        justifyContent='space-between'
        px={2}
        py={1}
        sx={{
          backgroundColor: '#E9E2DC',
        }}
      >
        {typeof title === 'string' ? (
          <Stack
            flexDirection='row'
            alignItems='center'
            gap={1}
            color='#916D4F'
          >
            {icon}
            <Typography
              fontFamily='Inter, sans-serif'
              fontWeight='bold'
              fontSize={18}
              sx={{ color: '#906d4f' }}
              {...titleAttributes}
            >
              {title}
            </Typography>
          </Stack>
        ) : (
          <Fragment>{title}</Fragment>
        )}
        {headerCta}
      </Stack>
      <Stack>{content}</Stack>
    </Card>
  );
};

export { ContentCard };
