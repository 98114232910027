import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../hooks/useMsalIdToken';
import { getAppConfig } from '../../appConfig';

export const useMyceetya = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { data, isFetching, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['myceetya-current-form'],
    enabled: !!token,
    queryFn: async () => {
      const res = await axios.get(
        `${appConfig.app.baseUrl!}form/getcurrentform`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return res.data;
    },
  });

  return {
    currentMyceetya: data,
    isFetching,
    isLoading,
  };
};

export const useMyceetyaMutation = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const createMyceetya = useMutation({
    mutationFn: async (payload: unknown) => {
      const response = await axios.post(
        `${appConfig.app.baseUrl!}form/createmyceetyaform`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response;
    },
  });

  return createMyceetya;
};
