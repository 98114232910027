import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAppConfig } from '../appConfig';
import {
  SchoolMenuType,
  SchoolPageBlockType,
} from '../types/schoolinformation.type';
const appConfig = getAppConfig();

export const api = createApi({
  reducerPath: 'cocurricular',
  baseQuery: fetchBaseQuery({ baseUrl: appConfig.app.baseUrl! }),
  endpoints: (build) => ({
    getCoCurricularInformation: build.query<
      Array<SchoolMenuType>,
      { token?: string | null }
    >({
      query: ({ token }) => ({
        url: 'umbraco/getcocurricularinformation',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getSchoolSubNavMenu: build.query({
      query: ({ token, path }) => ({
        url: `umbraco/getsubnavmenu`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          path: encodeURIComponent(String(path)),
        },
      }),
    }),
    getCocurricularInformationPath: build.query<
      SchoolPageBlockType,
      { token: string; path: string }
    >({
      query: ({ token, path }) => ({
        url: `umbraco/getcocurricularinformationpagebypath`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          path: encodeURIComponent(String(path)),
        },
      }),
    }),
  }),
});

export const {
  useGetCoCurricularInformationQuery,
  useGetSchoolSubNavMenuQuery,
  useGetCocurricularInformationPathQuery,
} = api;
