import { Button, Stack, Typography } from '@mui/material';
import { Dialog } from '../../../components/dialog';
import { Fragment, useEffect, useState } from 'react';
import { TodoExcursionType } from '../../../types/todo.types';
import { useGetPupils } from '../../../hooks/useGetPupils';
import { useAddExcursionPermissionMutation } from '../../../services/parent.service';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { useQueryClient } from '@tanstack/react-query';
import { displayTime12HourFormat } from '../../../utils/displayTime';
import {
  formatActivityDate,
  IsMultiDayExcursion,
  formatMultiDayActivityDate,
} from '../formatDateTime';

interface Props {
  open: boolean;
  excursion: TodoExcursionType;
  handleClose: () => void;
  setPermission: (
    type: 'success' | 'error' | 'default',
    currentStudent: string,
    message: string,
  ) => void;
  readonly?: boolean;
}

export const TodoExcursionInfo = ({
  open,
  excursion,
  handleClose,
  setPermission,
  readonly = false,
}: Props) => {
  const token = useMsalIdToken();
  const queryClient = useQueryClient();
  const [view, setView] = useState<'info' | 'permission'>('info');
  const [permissionType, setPermissionType] = useState<
    null | 'give' | 'refuse'
  >(null);
  const { getPupilInfo } = useGetPupils();
  const currentStudent = getPupilInfo(excursion?.pupilID).forename;

  const [addExcursionPermission, { isLoading, isSuccess, isError }] =
    useAddExcursionPermissionMutation();

  const handleSubmit = async ({
    pupilId,
    activityId,
    isGranted,
  }: {
    pupilId: string;
    activityId: number;
    isGranted: 'true' | 'false';
  }) => {
    const payload = {
      pupilId,
      activityId,
      isGranted,
    };

    void addExcursionPermission({ token, payload }).then(async () => {
      await queryClient.invalidateQueries({
        queryKey: ['todos-excursions'],
      });

      await queryClient.invalidateQueries({
        queryKey: ['todos-excursions-completed'],
      });
    });
  };

  useEffect(() => {
    if (!isSuccess && !isError) {
      return;
    } else {
      setPermission(
        'success',
        String(currentStudent),
        isSuccess
          ? `Successfully ${permissionType} permission to ${currentStudent}`
          : `Error setting the permission for ${currentStudent}. Please try again later.`,
      );
      handleClose();
    }
  }, [isSuccess, isError]);

  if (!excursion) {
    return null;
  }

  const ViewInfo = () => {
    return (
      <Stack py={2}>
        <Stack flexDirection='column' flex={1} gap={3}>
          <Stack flexDirection='column'>
            <Stack flex={4}>
              <Typography fontSize={12} fontWeight={600}>
                Description
              </Typography>
              <Typography fontSize={14} fontWeight={300}>
                {excursion?.description}
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection='row'>
            <Stack flex={1}>
              <Typography fontSize={12}>Activity Day & Time</Typography>
              {IsMultiDayExcursion(
                String(excursion.runsFrom),
                String(excursion.runsTo),
              ) ? (
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  dangerouslySetInnerHTML={{
                    __html: formatMultiDayActivityDate(
                      String(excursion.runsFrom),
                      String(excursion.runsTo),
                      excursion.startTime,
                      excursion.endTime,
                    ),
                  }}
                />
              ) : (
                <>
                  <Typography fontSize={14} fontWeight={700}>
                    {formatActivityDate(String(excursion.runsFrom))}
                  </Typography>
                  <Typography fontSize={14} fontWeight={700}>
                    {displayTime12HourFormat(excursion.startTime)} -{' '}
                    {displayTime12HourFormat(excursion.endTime)}
                  </Typography>
                </>
              )}
            </Stack>
            <Stack flex={1}>
              <Typography fontSize={12}>Activity Location</Typography>
              <Typography fontSize={14} fontWeight={700}>
                {excursion?.siteName}
              </Typography>
            </Stack>
          </Stack>

          <Stack flex={1}>
            <Typography fontSize={12}>Activity Staff:</Typography>
            <Typography fontSize={14} fontWeight={600}>
              {excursion?.nameToAppearOnReport}
            </Typography>
          </Stack>

          <Stack flex={1}>
            <Typography fontSize={12} fontWeight={600}>
              What to bring:
            </Typography>
            <Typography fontSize={14} fontWeight={300}>
              {excursion?.whatToBring}
            </Typography>
          </Stack>

          <Stack flex={1}>
            <Typography fontSize={12} fontWeight={600}>
              Uniform:
            </Typography>
            <Typography fontSize={14} fontWeight={300}>
              {excursion?.uniform}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const ViewPermission = () => {
    return (
      <Stack py={2}>
        <Stack display='inline'>
          <Typography fontSize={16} component='span' pr={0.5}>
            {`Please confirm that you ${permissionType} permission for`}
          </Typography>
          <Typography fontSize={16} fontWeight={600} component='span' pr={0.5}>
            {getPupilInfo(excursion?.pupilID).forename}
          </Typography>
          <Typography fontSize={16} component='span' pr={0.5}>
            to attend
          </Typography>
          <Typography fontSize={16} fontWeight={600} component='span' pr={0.5}>
            {excursion?.name}.
          </Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <Dialog
      open={open}
      title={excursion.name}
      handleClose={() => {
        handleClose();
        setView('info');
      }}
      actions={
        <>
          {!readonly && (
            <Stack
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              px={2}
              py={1}
            >
              <Fragment>
                {view === 'info' ? (
                  <Fragment>
                    <Button
                      variant='outlined'
                      onClick={() => {
                        setView('permission');
                        setPermissionType('refuse');
                      }}
                    >
                      Refuse Permission
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setView('permission');
                        setPermissionType('give');
                      }}
                    >
                      Give Permission
                    </Button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Button
                      variant='outlined'
                      onClick={() => setView('info')}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() =>
                        void handleSubmit({
                          pupilId: excursion?.pupilID,
                          activityId: excursion?.id,
                          isGranted:
                            permissionType === 'give' ? 'true' : 'false',
                        })
                      }
                      disabled={isLoading}
                    >
                      {isLoading ? 'Confirming...' : 'Confirm'}
                    </Button>
                  </Fragment>
                )}
              </Fragment>
            </Stack>
          )}
        </>
      }
    >
      {view === 'info' && <ViewInfo />}
      {view === 'permission' && <ViewPermission />}
    </Dialog>
  );
};
