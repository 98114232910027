import { useState, useEffect } from 'react';
import { PupilType } from '../types/students.type';
import { useMsalIdToken } from './useMsalIdToken';
import { useStudents } from './useStudents';

const useGetSortedPupils = () => {
  const token = useMsalIdToken();
  const [sortedPupils, setSortedPupils] = useState<Array<PupilType>>([]);

  const { data: pupils, isFetching } = useStudents();

  useEffect(() => {
    if (pupils) {
      const groupedYearSchool = pupils
        ?.filter(
          (pupil: PupilType) =>
            pupil.yearGroupCode !== '013' && pupil.yearGroupCode !== '014',
        )
        ?.slice()
        .sort((first: PupilType, second: PupilType) => {
          return (
            Number(second?.yearGroup?.split(' ')?.[1]) -
            Number(first?.yearGroup?.split(' ')?.[1])
          );
        }) as Array<PupilType>;

      const groupedPreYearSchool = pupils
        ?.filter(
          (pupil: PupilType) =>
            pupil.yearGroupCode === '013' || pupil.yearGroupCode === '014',
        )
        ?.slice()
        .sort((first: PupilType, second: PupilType) => {
          return Number(first?.yearGroupCode) - Number(second?.yearGroupCode);
        }) as Array<PupilType>;
      setSortedPupils([...groupedYearSchool, ...groupedPreYearSchool]);
    }
  }, [pupils]);

  return {
    pupils: sortedPupils,
    isLoadingPupils: isFetching,
    isFetchingPupils: isFetching,
  };
};

export { useGetSortedPupils };
