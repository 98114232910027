export const displayTime = (date: string) => {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
};

export const displayTime12HourFormat = (time: string) => {
  const [hour, minute] = time.split(':');
  const hourNumber = Number(hour);
  const hourIn12HourFormat = hourNumber % 12 || 12;
  const period = hourNumber < 12 ? 'AM' : 'PM';
  return `${hourIn12HourFormat}:${minute} ${period}`;
};
