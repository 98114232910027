export const withHttp = (url: string) => {
  if (url.includes('http://') || url.includes('https://')) {
    return url;
  }
  return `https://${url}`;
};

export const convertLinkString = (text: string) => {
  const urlRegex =
    /(?!<a[^>]*>[^<])(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])(?![^<]*<\/a>)/gi;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '">' + url + '</a>';
  });
};
