import { Link, Typography } from '@mui/material';

interface Props {
  fileName: string;
  url: string;
}

const AttachmentFile = ({ fileName, url }: Props) => {
  return (
    <Link target='_blank' href={url}>
      <Typography fontSize={14}>{fileName}</Typography>
    </Link>
  );
};

export { AttachmentFile };
