import { Snackbar, Alert } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

interface Props {
  isOpen: boolean;
  type: 'success' | 'error';
  message: ReactNode | string;
}

const ToastView = ({ isOpen, type, message }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={1000000000000}
      onClose={handleClose}
    >
      <Alert
        severity={type === 'error' ? 'error' : 'success'}
        onClose={handleClose}
        sx={{ fontSize: 18, alignItems: 'center' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export { ToastView };
