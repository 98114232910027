import { countries } from '../../config';
import { WrapperView } from './WrapperView';

export const StudentCountryOfBirth = ({
  country,
  otherCountry,
}: {
  country: string;
  otherCountry: string;
}) => {
  const value =
    country !== '0000'
      ? countries.filter((countryItem) => countryItem.code === country)?.[0]
          ?.name ?? country
      : otherCountry;

  return <WrapperView label='Country of Birth' value={value} />;
};
