/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { api as schoolApi } from '../../services/school.service';
import { api as cocurricularApi } from '../../services/co-curricular.service';
import { api as eventsApi } from '../../services/events.service';
import { api as noticesApi } from '../../services/notices.service';
import { api as parentApi } from '../../services/parent.service';
import { api as activityApi } from '../../services/activity.service';
import { api as medicalApi } from '../../services/medical.service';
import { api as myceetyaApi } from '../../services/myceetya.service';

import { reducer as noticesReducer } from '../slices/notices.slice';
import { reducer as eventsReducer } from '../slices/events.slice';
import { reducer as studentReducer } from '../slices/student.slice';
import { reducer as todosReducer } from '../slices/todo.slice';
import { reducer as admiParentReducer } from '../slices/admin-parent.slice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['noticesStore', 'eventsStore'],
};

const reducers = combineReducers({
  noticesStore: noticesReducer,
  eventsStore: eventsReducer,
  student: studentReducer,
  todos: todosReducer,
  adminParent: admiParentReducer,
  [schoolApi.reducerPath]: schoolApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [noticesApi.reducerPath]: noticesApi.reducer,
  [parentApi.reducerPath]: parentApi.reducer,
  [medicalApi.reducerPath]: medicalApi.reducer,
  [activityApi.reducerPath]: activityApi.reducer,
  [cocurricularApi.reducerPath]: cocurricularApi.reducer,
  [myceetyaApi.reducerPath]: myceetyaApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      schoolApi.middleware,
      eventsApi.middleware,
      noticesApi.middleware,
      parentApi.middleware,
      medicalApi.middleware,
      myceetyaApi.middleware,
      activityApi.middleware,
      cocurricularApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
