import { Box, Button, Divider, Link, Stack, Typography } from '@mui/material';
import { Dialog } from '../../../components/dialog';
import { displayMonthDay } from '../../../utils/displayMonthDay';
import { displayUnixToDate } from '../../../utils/displayUnixToDate';
import { EventUmbracoItemType } from '../../../types/events.type';
import { useEventDate } from './useEventDate';
import { useState } from 'react';
import { RsvpForm } from './rsvp-form';
import { withHttp } from '../../../utils/linkCheck';

interface Props {
  open: boolean;
  event?: EventUmbracoItemType | null;
  handleClose: () => void;
}

export const EventInfo = ({ open, event, handleClose }: Props) => {
  const { displayDate } = useEventDate(
    String(event?.startAt),
    String(event?.endAt),
  );

  const [view, setView] = useState<'info' | 'form'>('info');

  if (!event) {
    return null;
  }

  const ViewInfo = () => {
    return (
      <Stack py={3}>
        <Stack alignItems='flex-end' pb={2}>
          <Typography fontSize={12} fontWeight={600}>
            {displayDate}
          </Typography>
        </Stack>

        {event.imageUrl && (
          <Stack
            alignItems='center'
            justifyContent='center'
            width='fit-content'
            py={2}
          >
            <img src={event.imageUrl} alt={event?.name} width='75%' />
          </Stack>
        )}
        <Typography
          variant='body1'
          color='black'
          dangerouslySetInnerHTML={{ __html: event?.description }}
        />

        <Stack flexDirection='row' gap={2} pt={3}>
          {event.eventLinkUrl && event.eventLinkTitle && (
            <Button
              component='a'
              target='_blank'
              href={withHttp(event.eventLinkUrl)}
              variant='contained'
            >
              {event.eventLinkTitle}
            </Button>
          )}
          {event.enableRSVP && !event?.submittedSVP && (
            <Button variant='outlined' onClick={() => setView('form')}>
              RSVP
            </Button>
          )}
        </Stack>
      </Stack>
    );
  };

  const ViewForm = () => {
    return (
      <Stack>
        <RsvpForm event={event} />
      </Stack>
    );
  };

  return (
    <Dialog
      open={open}
      title={event.name}
      handleClose={handleClose}
      actions={
        <Stack
          flexDirection='row'
          width='100%'
          justifyContent={view === 'form' ? 'space-between' : 'flex-end'}
          px={2}
          py={1}
        >
          {view === 'form' && (
            <Button variant='outlined' onClick={() => setView('info')}>
              Back to Info
            </Button>
          )}
          <Button onClick={handleClose}>Close</Button>
        </Stack>
      }
    >
      {view === 'info' ? <ViewInfo /> : null}
      {view === 'form' ? <ViewForm /> : null}
    </Dialog>
  );
};
