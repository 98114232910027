export function checkArrayEqual(array1: Array<string>, array2: Array<string>) {
  if (!array1) {
    return false;
  }

  const sortedArr1 = [...array1].sort();
  const sortedArr2 = [...array2].sort();

  return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
}
