import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio as MuiRadio,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';

interface Props extends RadioGroupProps {
  label?: string;
  defaultValue?: string | number;
  options: { value: string | number; label: string }[];
}

// Reference: https://mui.com/material-ui/api/radio-group/
const FormRadio = ({ label, defaultValue, options, ...props }: Props) => {
  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup defaultValue={defaultValue} {...props}>
        {options?.map((option) => (
          <FormControlLabel
            key={option.label}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export { FormRadio };
