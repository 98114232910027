import { displayMonthDay } from '../../../utils/displayMonthDay';

export const useEventDate = (startDate: string, endDate: string) => {
  let displayDate = '';

  const startDateFormatted = new Date(startDate);
  const endDateFormatted = new Date(startDate);

  const startMonth = startDateFormatted.getMonth();
  const startday = startDateFormatted.getDate();
  const startYear = startDateFormatted.getFullYear();

  const endMonth = endDateFormatted.getMonth();
  const endDay = endDateFormatted.getDate();
  const endYear = endDateFormatted.getFullYear();

  const start = `${startMonth}-${startday}-${startYear}`;
  const end = `${endMonth}-${endDay}-${endYear}`;

  if (start === end) {
    displayDate = displayMonthDay(String(startDate));
  } else {
    displayDate = `${displayMonthDay(String(startDate))} - ${displayMonthDay(
      String(endDate),
    )}`;
  }

  return {
    displayDate,
  };
};

export const useEventDateShortMonth = (startDate: string, endDate: string) => {
  const displayMonthDayShort = (date: string) => {
    const d = new Date(date);
    return `${d.getDate()} ${d.toLocaleString('default', { month: 'short' })}`;
  };

  let displayDateShortMonth = '';
  const startDateFormatted = new Date(startDate);
  const endDateFormatted = new Date(startDate);

  const startMonth = startDateFormatted.getMonth();
  const startday = startDateFormatted.getDate();
  const startYear = startDateFormatted.getFullYear();

  const endMonth = endDateFormatted.getMonth();
  const endDay = endDateFormatted.getDate();
  const endYear = endDateFormatted.getFullYear();

  const start = `${startMonth}-${startday}-${startYear}`;
  const end = `${endMonth}-${endDay}-${endYear}`;

  if (start === end) {
    displayDateShortMonth = displayMonthDayShort(startDate);
  } else {
    displayDateShortMonth = `${displayMonthDayShort(
      startDate,
    )} - ${displayMonthDayShort(endDate)}`;
  }

  return {
    displayDateShortMonth,
  };
};
