import jwtDecode, { JwtPayload } from 'jwt-decode';

interface PayloadType extends JwtPayload {
  alternateloginid: string;
  clientuseragent: string;
  groupsid: string[];
  unique_name: string;
  upn: string;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid': string;
  email: string;
}

export const useTokenDecode = (token: string) => {
  if (!token) {
    return null;
  }

  return jwtDecode<PayloadType>(token);
};
