import { Button, Drawer, Stack, Typography } from '@mui/material';
import { ContentCard } from '../../../components/layout/ContentCard';
import { SubscribedTagsEmpty } from './subscribed-tags-empty';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useSubscribedTags,
  useSubscribedTagsMutation,
} from './useSubscribedTags';
import { SubscribedTagsList } from './subscribed-tags-list';
import { SubscribedTagsDrawerContent } from './subscribed-tags-drawer-content';
import { LoadingView } from './loading-view';
import StatusMessage from '../../../components/status-message';
import { toast } from 'react-toastify';

export type SelectedTagsType = {
  categoryId: number;
  tagIds: Array<number>;
};

export const SubscribedTags = () => {
  const queryClient = useQueryClient();
  const [isTagsOpen, toggleTags] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
  const [payload, setPayload] = useState<Array<SelectedTagsType>>([]);
  const [preSubmitPayload, setPreSubmitPayload] =
    useState<Array<SelectedTagsType> | null>(null);

  const { tags, isLoading, error } = useSubscribedTags();
  const { updateTags, isSaving, isSuccess } = useSubscribedTagsMutation();

  const handleSelectedTags = (value: string) => {
    if (selectedTags.includes(value)) {
      const filteredTags = selectedTags.filter((item) => item !== value);
      setSelectedTags(filteredTags);
      return;
    }
    setSelectedTags([...selectedTags, value]);
  };

  const handleSubmit = () => {
    selectedTags?.map((sTags) => {
      /**
       * Get the category and tagId from the value
       */
      const key = sTags.split('-')[0];
      const value = sTags.split('-')[1];
      /**
       * Get the index value from the payload
       */
      const currentIndex = payload.findIndex(
        (idx) => idx.categoryId === Number(key),
      );
      /**
       * Create a reference of the payload
       */
      const referencePayload = [...payload];
      /**
       * Check if the tagId value already exists, remove if already exist
       */
      if (referencePayload[currentIndex].tagIds?.includes(Number(value))) {
        referencePayload[currentIndex].tagIds = referencePayload[
          currentIndex
        ].tagIds?.filter((tagId) => tagId !== Number(value));
      } else {
        referencePayload[currentIndex].tagIds = [
          ...referencePayload[currentIndex].tagIds,
          Number(value),
        ];
      }
      /**
       * Set the reference payload as the final payload
       */
      setPreSubmitPayload(referencePayload);
    });
  };

  const Content = () => {
    return (
      <Stack maxHeight='calc(calc(100vh - 80px) / 2.6)' overflow='auto' px={2}>
        {tags?.suscribedTags ? (
          <SubscribedTagsList tags={tags} />
        ) : (
          <SubscribedTagsEmpty>
            <Button
              variant='text'
              sx={{ maxWidth: 'max-content' }}
              onClick={() => toggleTags(true)}
            >
              <Typography
                variant='button'
                color='#616D8A'
                borderBottom='1px solid #616D8A'
                textTransform='none'
                fontWeight={300}
              >
                Subscribe to tags
              </Typography>
              <ArrowForward sx={{ color: '#616D8A', fontSize: 16, pl: 0.5 }} />
            </Button>
          </SubscribedTagsEmpty>
        )}
      </Stack>
    );
  };

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load Subscribed tags.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  useEffect(() => {
    const initialPayload = tags?.tagCategories?.map((cat) => ({
      categoryId: cat?.id,
      tagIds: [],
    }));

    setPayload(initialPayload as Array<SelectedTagsType>);
  }, [tags]);

  useEffect(() => {
    if (preSubmitPayload) {
      void updateTags(
        preSubmitPayload?.filter((item) => item.tagIds?.length !== 0),
      );
    }
  }, [preSubmitPayload]);

  useEffect(() => {
    if (isSuccess) {
      void queryClient.invalidateQueries({
        queryKey: ['subscribed-tags'],
      });
      setPreSubmitPayload(null);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (tags?.suscribedTags) {
      const filteredTags = tags?.suscribedTags?.map((item) => {
        return item?.tags?.map((itemTag) => {
          return `${item?.tagCategoryId}-${itemTag?.id}`;
        });
      });
      setSelectedTags(filteredTags?.flat());
    }
  }, [tags?.suscribedTags]);

  return (
    <>
      <ContentCard
        title='Subscribed Tags'
        titleAttributes={{ 'data-tour': 'subscribed-tags' }}
        content={<>{isLoading ? <LoadingView /> : <Content />}</>}
        headerCta={
          <>
            {tags?.suscribedTags && (
              <Button
                variant='text'
                sx={{ display: 'flex', maxWidth: 'max-content' }}
                onClick={() => toggleTags(true)}
              >
                <Typography color='primary' fontWeight={300} fontSize={12}>
                  EDIT
                </Typography>
              </Button>
            )}
          </>
        }
      />
      <Drawer
        variant='temporary'
        anchor='right'
        open={isTagsOpen}
        onClose={() => toggleTags(false)}
        PaperProps={{
          sx: {
            width: { xs: '90%', md: '568px' },
          },
        }}
      >
        <SubscribedTagsDrawerContent
          categories={tags?.tagCategories ?? []}
          onClose={() => toggleTags(false)}
          handleSelectedTags={handleSelectedTags}
          selectedTags={selectedTags}
          footer={
            <Stack
              borderTop='1px solid rgba(0,0,0,0.2)'
              py={2}
              px={3}
              height='36px'
            >
              <Stack flexDirection='row' justifyContent='space-between'>
                <Button
                  variant='outlined'
                  disabled={isSaving}
                  onClick={() => toggleTags(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  disabled={isSaving}
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          }
        />
      </Drawer>

      <StatusMessage
        isOpen={isSuccess}
        message='Successfully updated the subscribed tags.'
        status='success'
      />
    </>
  );
};

export default SubscribedTags;
