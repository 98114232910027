import { Box, CircularProgress, Stack } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { ToastContainer } from 'react-toastify';
import { PATHS } from '../types';
import { routeName } from '../config/route.config';
import { PageLayout } from './PageLayout';
import { useTrack } from '../hooks/useTrack';
import ParentSelect from '../modules/dashboard/parent-select/parent-select';

interface Props {
  children?: ReactNode;
}

export const AppLayout = ({ children }: Props) => {
  const { pathname } = useLocation();
  const { trackPage } = useTrack();
  const activeRoute =
    routeName?.[pathname.split('/')[pathname.split('/').length - 1]];

  const LoadingView = () => {
    return (
      <Stack
        alignItems='center'
        justifyContent='center'
        height='100dvh'
        width='100dvw'
        gap={4}
        sx={{ bgcolor: (theme) => theme.palette.primary.main }}
      >
        <Box>
          <img src='/logo.png' alt='MLC' />
        </Box>
        <CircularProgress size={40} sx={{ color: '#FFFFFF' }} />
      </Stack>
    );
  };

  useEffect(() => {
    trackPage({
      title: document.title,
      page: activeRoute,
    });
  }, [pathname]);

  if (pathname === PATHS.ROOT) {
    return children;
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <LoadingView />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <PageLayout activeRoute={activeRoute} parentSelect={<ParentSelect />}>
            {children}
          </PageLayout>
          <ToastContainer position='bottom-left' />
        </Box>
      </AuthenticatedTemplate>
    </>
  );
};
