import { RefObject, useEffect, useMemo, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayjs, { Dayjs } from 'dayjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import FilterListIcon from '@mui/icons-material/FilterList';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Stack,
  Button,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  Tooltip,
  Drawer,
  Divider,
  TextField,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export interface Props {
  calendarRef: RefObject<FullCalendar>;
  isMobile?: boolean;
}

const CalendarHeader = ({ calendarRef, isMobile }: Props) => {
  const [date, setDate] = useState<Dayjs | null>(
    dayjs(calendarRef.current?.getApi().getDate()),
  );
  const [activeView, setActiveView] = useState('listWeek');
  const [isOptionsOpen, showOptions] = useState(false);

  const selectedDate = useMemo(() => {
    return date;
  }, [date]);

  const SelectView = () => {
    return (
      <FormControl>
        <InputLabel id='calendar-view-label-select'>View</InputLabel>
        <Select
          labelId='calendar-view-label-select'
          id='calendar-view-select'
          value={activeView}
          label='View'
          onChange={(event) => {
            setActiveView(event?.target?.value);
            calendarRef?.current?.getApi().changeView(event?.target?.value);
          }}
        >
          <MenuItem value='listMonth'>List Month</MenuItem>
          <MenuItem value='listWeek'>List Week</MenuItem>
          <MenuItem value='listDay'>List Day</MenuItem>
          <MenuItem value='timeGridDay'>Grid Day</MenuItem>
          {!isMobile && <MenuItem value='timeGridWeek'>Grid Week</MenuItem>}
          {!isMobile && <MenuItem value='dayGridMonth'>Grid Month</MenuItem>}
        </Select>
      </FormControl>
    );
  };

  const DatePickerView = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Selected Date'
          value={date}
          format={activeView === 'listMonth' ? 'MMMM' : 'YYYY-MM-DD'}
          onChange={(newValue: Dayjs | null): void => {
            if (newValue?.isValid() === true) {
              setDate(newValue);
              calendarRef.current?.getApi().gotoDate(newValue?.toDate() as any);
              return;
            }
          }}
          views={
            activeView === 'listMonth'
              ? ['month', 'year']
              : ['year', 'month', 'day']
          }
        />
      </LocalizationProvider>
    );
  };

  const handleDateChange = (direction: 'prev' | 'today' | 'next'): void => {
    const calApi = calendarRef.current?.getApi();

    if (calApi) {
      if (direction === 'prev') {
        calApi.prev();
      } else if (direction === 'next') {
        calApi.next();
      } else {
        calApi.today();
      }

      setDate(dayjs(calApi.getDate()));
    }
  };

  useEffect(() => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      setActiveView(calApi.view.type);
      setDate(dayjs(calApi.getDate()));
    }
  }, [calendarRef]);

  return (
    <Stack
      alignItems='center'
      justifyContent='space-between'
      direction={{ xs: 'column', md: 'row' }}
      spacing={2}
      pb={2}
    >
      {isMobile && (
        <Stack alignItems='flex-end' width='100%'>
          <Button
            endIcon={<FilterListIcon />}
            variant='contained'
            size='small'
            onClick={() => showOptions(true)}
          >
            Filter
          </Button>
        </Stack>
      )}
      <Stack
        alignItems='center'
        flexDirection='row'
        justifyContent='space-between'
        width='100%'
        gap={2}
      >
        <Stack>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 24 },
              fontWeight: 700,
            }}
          >
            {calendarRef.current?.getApi().view.title}
          </Typography>
        </Stack>

        <Stack flexDirection='row' alignItems='center' gap={1}>
          <Tooltip title='Previous'>
            <IconButton
              color='primary'
              onClick={(): void => handleDateChange('prev')}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Next'>
            <IconButton
              color='primary'
              onClick={(): void => handleDateChange('next')}
            >
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Go to the date today'>
            <Button
              color='primary'
              size='small'
              variant='outlined'
              onClick={(): void => handleDateChange('today')}
            >
              Today
            </Button>
          </Tooltip>
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            sx={{ height: 'auto' }}
          />
          {!isMobile && (
            <>
              <Stack>
                <DatePickerView />
              </Stack>
              <Stack>
                <SelectView />
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      {isMobile && (
        <Drawer
          variant='temporary'
          anchor='right'
          open={isOptionsOpen}
          onClose={() => showOptions(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            style: {
              padding: 20,
              backgroundColor: '#FFFFFF',
              width: '70%',
            },
          }}
        >
          <Stack gap={1}>
            <Stack
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='body1' fontWeight={600}>
                Calendar Filters
              </Typography>
              <IconButton onClick={() => showOptions(false)} color='primary'>
                <CancelIcon />
              </IconButton>
            </Stack>
            <Stack gap={4}>
              <Divider />
              <Stack>
                <DatePickerView />
              </Stack>
              <Stack>
                <SelectView />
              </Stack>
            </Stack>
          </Stack>
        </Drawer>
      )}
    </Stack>
  );
};

export { CalendarHeader };
