import { Typography } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
const FormLabelView = ({
  title,
  subtitle = '',
}: {
  title: string;
  subtitle?: string;
}) => {
  return (
    <FormLabel sx={{ fontSize: 16, color: '#000000' }}>
      {title}
      {subtitle && (
        <Typography component='small' display='block' fontSize={12} pt={0.5}>
          {subtitle}
        </Typography>
      )}
    </FormLabel>
  );
};
export { FormLabelView };
