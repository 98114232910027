export enum PATHS {
  // auth pages
  LOGIN = '/login',
  SIGNUP = '/signup',
  FORGOT_PASSWORD = '/forgot-password',
  CONFIRM = '/confirm',
  LOGOUT = '/logout',

  ROOT = '/',
  ADMIN = '/admin',
  PROFILE = '/profile',
  ACCOUNT = '/account',

  DASHBOARD = '/dashboard',
  MY_STUDENTS = '/my-students',
  TIMETABLE = '/timetable',
  SCHOOL_CALENDAR = '/calendar',
  SCHOOL_CONTACTS = '/contacts',
  SCHOOL_INFORMATION = '/school-information',
  COCURRICULAR = '/co-curricular-information',
  TAKEATOUR = '/takeatour',
}
