export const displayMonthDay = (date: string, showYear = false) => {
  let month = '';
  let day = '';
  let year = '';

  if (!date) {
    return '';
  }

  month = new Date(date)?.toLocaleString('default', { month: 'long' });
  day = new Date(date)?.toLocaleString('default', { day: 'numeric' });
  year = new Date(date)?.toLocaleString('default', { year: 'numeric' });

  if (showYear) {
    return `${day} ${month} ${year}`;
  }

  return `${day} ${month}`;
};
