import { Dialog, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../state/stores/root.store';
import { InfoView } from './view/info';
import { useGetPupils } from '../../../hooks/useGetPupils';
import { StudentAvatar } from '../../../components/student-list/student-avatar';
import { ConfirmView } from './view/confirm';
import { EditView } from './view/edit';
import { SuccessView } from './view/success';
import { SummaryView } from './view/summary';
import {
  useUpdateMedicalInformationMutation,
} from '../../../services/medical.service';
import { useGetMedicalInformation } from '../hooks/useGetMedicalInformation';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { MedicalInfoProvider, ViewType } from './useMedicalInformation';

interface MedicalInformationProps {
  open: boolean;
  handleClose: () => void;
}

export const MedicalInformation = ({
  open,
  handleClose,
}: MedicalInformationProps) => {
  const token = useMsalIdToken();
  const { currentStudent } = useAppSelector((state) => state.student);

  const { data } = useGetMedicalInformation();

  const [
    updateMedicalInformation,
    {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
    },
  ] = useUpdateMedicalInformationMutation();

  const [viewState, setViewState] = useState<ViewType>('view');
  const [medications, setMedications] = useState<Array<string>>([]);
  const [allergies, setAllergies] = useState<Array<string>>([]);
  const [ailments, setAilments] = useState<Array<string>>([]);
  const [other, setOther] = useState<Record<string, string>>({});
  const [hasChanges, setHasChanges] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  const handleView = (view: ViewType) => {
    setViewState(view);
  };

  const handleSave = () => {
    const payload = {
      pupilId: currentStudent?.pupilId,
      medications,
      allergies,
      ailments,
      ...other,
    };

    void updateMedicalInformation({
      token,
      payload,
    });
  };

  const handleModalClose = () => {
    // If Success - close immediately
    if (viewState === 'success') {
      return handleClose();
    }

    setConfirmClose(true);

    if (hasChanges) {
      return handleView('confirm');
    }

    return handleClose();
  };

  const StudentInfo = () => {
    return (
      <Stack flexDirection='row' alignItems='center' gap={2}>
        <StudentAvatar
          pupilId={currentStudent?.pupilId ?? ''}
          fullName={`${currentStudent?.forename} ${currentStudent?.surname}`}
          size={50}
          acronym={currentStudent?.avatarAcronym ?? ''}
        />
        <Stack>
          <Typography fontWeight={700} fontSize={20} color='primary'>
            {currentStudent?.forename}
          </Typography>
          <Typography fontWeight={500} fontSize={14}>
            {currentStudent?.yearGroup}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const MainView = () => {
    switch (viewState) {
      case 'view':
        return (
          <InfoView>
            <StudentInfo />
          </InfoView>
        );
      case 'edit':
        return (
          <EditView>
            <StudentInfo />
          </EditView>
        );
      case 'summary':
        return (
          <SummaryView>
            <StudentInfo />
          </SummaryView>
        );
      case 'confirm':
        return <ConfirmView />;
      default:
        return <SuccessView handleClose={handleClose} />;
    }
  };

  const handleReset = () => {
    if (data) {
      setMedications([...data?.medications]);
      setAllergies([...data?.allergies]);
      setAilments([...data?.ailments]);
    }
  };

  const memoizedValues = useMemo(
    () => ({
      view: 'view',
      data,
      medications,
      allergies,
      ailments,
      hasChanges,
      isUpdating,
      isUpdateSuccess,
      isUpdateError,
      confirmClose,
      other,
      setMedications,
      setAllergies,
      setAilments,
      handleView,
      handleReset,
      handleSave,
      handleClose,
      setHasChanges,
      setConfirmClose,
      setOther,
    }),
    [
      data,
      medications,
      allergies,
      ailments,
      other,
      confirmClose,
      isUpdating,
      isUpdateSuccess,
      isUpdateError,
    ],
  );

  useEffect(() => {
    if (data) {
      setMedications([...data?.medications]);
      setAllergies([...data?.allergies]);
      setAilments([...data?.ailments]);
    }
  }, [data]);

  return (
    <MedicalInfoProvider value={memoizedValues}>
      <Dialog
        disableEscapeKeyDown={viewState === 'success'}
        open={open}
        onClose={handleModalClose}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'visible',
          },
        }}
      >
        {MainView()}
      </Dialog>
    </MedicalInfoProvider>
  );
};
