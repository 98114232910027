import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Notices } from '../../modules/dashboard/notices';
import { Events } from '../../modules/dashboard/events';
import { ComingUp } from '../../modules/dashboard/coming-up';
import { Todo } from '../../modules/dashboard/todo';
import { useTour } from '@reactour/tour';
import { useLocation } from 'react-router-dom';
import { PATHS } from '../../types';
import ParentSelect from '../../modules/dashboard/parent-select/parent-select';
import { useAppSelector } from '../../state/stores/root.store';

export const AdminPage = () => {
  const { isAdmin } = useAppSelector((state) => state.adminParent);

  return <Box>{isAdmin ? <ParentSelect /> : null}</Box>;
};
