import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { getAppConfig } from '../../../appConfig';
import { useAppSelector } from '../../../state/stores/root.store';
import {
  FlatTimeTableDateType,
  CoCurricularType,
  ExcursionType,
} from '../../../types/timetablecomingup.type';

// Define the structure of the response from the API
interface ComingUpResponse {
  [pupilId: string]: FlatTimeTableDateType;
}

export const useComingUp = () => {
  const token = useMsalIdToken();
  const appConfig = getAppConfig();

  const { activeParent } = useAppSelector((state) => state.adminParent);

  const { data, error, isFetching } = useQuery<ComingUpResponse>({
    refetchOnWindowFocus: false,
    queryKey: ['coming-up', `coming-up-${activeParent?.email}`],
    enabled: !!token,
    queryFn: async () => {
      const response = await axios(
        `${appConfig.app.baseUrl!}timetable/getchildrencomingupinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'parent-email': activeParent?.email,
          },
        },
      );

      const groupedResponse: ComingUpResponse = {};

      if (!response?.data || response.data.length === 0) return groupedResponse;

      // Group the response by pupilId
      response.data.forEach((item: FlatTimeTableDateType) => {
        groupedResponse[item.pupilId] = {
          pupilId: item.pupilId,
          nextCoCurricularActivity: item.nextCoCurricularActivity,
          nextExcursion: item.nextExcursion,
        };
      });

      return groupedResponse;
    },
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
